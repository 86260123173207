import React, { useMemo, useState, useEffect } from "react";

// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useTheme } from "@material-ui/core/styles";

import {
  CircularProgress,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import awsExports from "../../aws-exports";

import { Box } from "@material-ui/core";

import "./phoneInput.styles.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { Theme } from "../../aws-exports";
import config from "../../aws-exports";
import logincss from "../../pages/Login/Login.module.scss";
import { motion } from "framer-motion";

// import AWS from "aws-sdk";
// import jwt_decode from "jwt-decode";
// import Cookies from "js-cookie";
// import axios from "axios";
// import { StorageHelper } from "@aws-amplify/core";
import { useQueryParams } from "../../Hooks/useQueryParams";
import { useDispatch } from "react-redux";
import { awsSignIn } from "../../redux/auth/authSlice";
import { API, Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { languageChange } from "../../api/api";
import SignInIcici from "./SignInIcici";

export const inputBoxStyle = {
  width: "450px",
  margin: "1rem",
  fontFamily: "myfontregular",
  "@media (max-width: 760px)": {
    width: "100%",
  },
};
export const displayCenter = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const inputIconStyle = {
  color: Theme.color.primary,
  height: "22px",
  width: "22px",
};

export const ButtonIconStyle = {
  color: Theme.color.primary,
};

export const SignInAndSignUpValues = React.createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: "500px",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  logoImage: {
    width: "200px",
    "@media (min-width: 760px)": {
      width: "45%",
    },
  },
}));
const SignInAndSignUp = () => {
  //Responsive Dialog
  //const userDetails = useSelector(awsSignIn);
 // const theme = useTheme();
  //Bool State for SignInAndSignUp
  const history = useHistory();
  const query = useQueryParams();
  const location = useLocation();

  const dispatch = useDispatch();

  const [signIn, setSignIn] = useState(true);
  const [login, setLogin] = useState(true);
  const [signUp, setSignUp] = useState(false);
  const classes = useStyles();
  const SignInAndSignUpProvider = useMemo(
    () => ({
      signIn,
      setSignIn,
      signUp,
      setSignUp,
    }),
    [signIn, setSignIn, signUp, setSignUp]
  );
  useEffect(() => {
    if (config.aws_org_id == "nseacademy") {
      setLogin(true);
      checkNseUserLogin();
    } else {
      setLogin(false);
    }
  }, []);
 
  async function checkNseUserLogin() {  
    const params = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    if ((query.get("id") && query.get("uid")) || (params.get("id") 
        && params.get("uid")) || (hashParams.get("id") && hashParams.get("uid"))) {
    let email = query.get("uid") || params.get("uid") || hashParams.get("uid"); 
    let cid = query.get("id") || params.get("id") || hashParams.get("id");
            try {
                email = email+"@enhanzed.com"; 
                await Auth.signIn(email, "Securep@ss12");  
                const info = await Auth.currentUserInfo(); 
                let userData = info.attributes;
                userData.username = info.username;
                userData.id = info.id;
                userData.cid = cid;
                userData.emailid = email;
                let authkey = localStorage.getItem("accessToken");
                const tokenrequestBody = {
                  client_key: email,
                  client_secret: config.secretKeyAfter, 
                  schema: config.schema
                };
                const tokenjsonString = JSON.stringify(tokenrequestBody);
                const tokenbase64EncodedString = btoa(tokenjsonString);
                const tokenreportInfo = `/getUserToken?json=${encodeURIComponent(
                  tokenbase64EncodedString
                )}`;
                const tokenresponse = await API.get(
                  awsExports.aws_cloud_logic_custom_name,
                  tokenreportInfo,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization:authkey
                    },
                  }
                );
                userData.accessToken = tokenresponse.accessToken;
                userData.refreshToken = tokenresponse.refreshToken;
                localStorage.setItem(
                  "after_logIn_accessToken",
                  tokenresponse?.accessToken
                );
                window.after_logIn_accessToken = tokenresponse?.accessToken
                userData.beforeAuth = authkey
                userData.location = {};
                
                await getUserDetails(userData);
              } catch (error) { 
                setLogin(false); 
              } 
      } else { 
        setLogin(false)
      } 
  }
 
  const getUserDetails = async (userdata) => { 
    try {
      const requestBody = {
        emailid: userdata.emailid,
            schema: config.schema,
            email: userdata.email,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getUserDetails?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",	
            Authorization:  userdata?.accessToken,
          },
        }
      );
      if (response && response.body?.length > 0 && response.body[0].enable) {
        userdata.uData = response.body[0];
        if (response?.body[0]?.oid !== undefined) {
          userdata.locale = response?.body[0]?.oid;
        }
        if (
          response?.body[0]?.first_name !== undefined &&
          response?.body[0]?.first_name !== null
        ) {
          userdata.name = response?.body[0]?.first_name;
        }

        await analyticsWebApp(userdata);
      }  
    } catch (err) { 
    }
  };
 
  async function analyticsWebApp(userDetails) {

    const jsonString = JSON.stringify({oid: config.aws_org_id,
      eventtype: "AuthenticatedViaCognito",
      id: userDetails.id,
      iid: config.aws_cognito_identity_pool_id,
      email: userDetails.username,
      name: userDetails.name,
      emailid: userDetails.email,
      tenant: userDetails.locale,
      ur_id: userDetails.uData?.ur_id,
      schema: config.schema,});
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
      );
      let lang = await languageChange(userDetails, userDetails.uData?.lang);
      userDetails.languagejson = lang?.languagejson;
      userDetails.themeDark = "light";
      dispatch(awsSignIn(userDetails));
      setLogin(false);
      let id = query.get("id");
    
      if (userDetails.emailid  && userDetails.cid) {
        history.push(`/course?id=${userDetails.cid}&uid=${query.get("uid")}`);
      } else if (location.pathname === "/content" && id) {
        history.push(`/content?id=${id}`);
      } else {
        history.replace({
          pathname: '/home',
          state: { poster: true }
        });
      }
    } catch (err) {
    }

  }
  return (
    <motion.div
      className={
        config.aws_org_id === "NKH"
          ? logincss.loginformdivColor
          : logincss.loginformdiv
      }
      style={{
        height: "100vh",
        display: "grid",
        placeItems: "center",
      }}
    >
      {login ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <motion.div
          className={logincss.loignformdiv3}
          style={{
            maxWidth: "750px",
          }}
          role="main"
          aria-label="Login Section"
          initial={{ x: "-100vw", opacity: 1 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 1,
            x: { type: "spring", stiffness: 100 },
            default: { duration: 2 },
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={`https://${
                config.DOMAIN
              }/${config.aws_org_id?.toLowerCase()}-resources/images/org-images/logo-dark.png`}
              alt="logo"
              height="100px"
              tabIndex={1}
            />
            {config.aws_org_id?.toLowerCase() === "icici" && (
              <img
                src={`https://${
                  config.DOMAIN
                }/${config.aws_org_id?.toLowerCase()}-resources/images/org-images/App-icon.png`}
                alt="logo"
                height="100px"
              />
            )}
          </Box>
    
          {config.aws_org_id?.toLowerCase() === "icici" ? (<SignInAndSignUpValues.Provider value={SignInAndSignUpProvider}>
            {signIn && <SignInIcici />}

           
          </SignInAndSignUpValues.Provider>):(<SignInAndSignUpValues.Provider value={SignInAndSignUpProvider}>
            {signIn && <SignIn />}

            {signUp && <SignUp />}
          </SignInAndSignUpValues.Provider>)}
          
        </motion.div>
      )}
    </motion.div>
  );
};

export default SignInAndSignUp;
