import React, { useEffect, useState } from "react";
import API from "@aws-amplify/api";
import config from "../../aws-exports";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";
import { Stack, Typography, Breadcrumbs } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@tanstack/react-query";
import { fetchData } from "../../api/api";
import { BarChart } from "@mui/x-charts";
import { PieChart } from "@mui/x-charts/PieChart";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import EmptyChart from "../../assets/Empty Chart.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  getAllCourses,
  getLearningPathDetails,
  dashboard,
  getMyTopics,
  timeSpent,
} from "../../api/api";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import Carousel from "../../components/Carousel/Carousel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import NothoingHereYet from "../../assets/Nothing here yet (Old).gif";

const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1E7BB9" : "#c1c1c1",
  },
}));
const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1EB91E" : "#7f7f7f",
  },
}));
const BorderLinearProgressEmpty = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 1,
  "& > span": {
    backgroundColor: "lightgray",
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 5,
  },
}));

function LearningAnalytics(props) {
  const [loading, setLoading] = useState(true);
  const [continuelearningCount, setContinueLearningCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalObjects, setTotalObject] = useState(0);
  const [totalLearning, setTotalLearning] = useState(0);
  const [totalSession, setTotalSession] = useState(0);
  const [completedCourse, setCompletedCourse] = useState([]);
  const [totalTimeHours, setTotalTimeHours] = useState(0);
  const [totalTimeMinutes, setTotalTimeMinutes] = useState(0);
  const [pData, setPData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const { cmpcount = 0, cncount = 0 } = props;
  const [rows, setRows] = useState([]);
  const [spin, setSpin] = useState(true);
  const userDetails = useSelector(authData);
  const [finalColumns, setFinalColumns] = useState([]);

  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    loaderSkeleton: {
      width: "200px",
      height: "3rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    subSection: {
      padding: "1.5rem",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#282D37 !important" : "#ffffff",
      borderRadius: "5px",
      width: "50%",
      boxShadow:
        userDetails.themeDark === "dark" ? "none" : "0px 0px 20px 0px #c3d0dc",
      "@media (max-width: 770px)": {
        width: "100%",
      },
    },
    thirdSection: {
      padding: "1.5rem",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#282D37 !important" : "#ffffff",
      borderRadius: "5px",
      width: "100%",
      marginTop: "3rem",
      boxShadow:
        userDetails.themeDark === "dark" ? "none" : "0px 0px 20px 0px #c3d0dc",
    },
    subContainer: {
      display: "flex",
      gap: "3rem",
      "@media (max-width: 770px)": {
        display: "grid",
      },
    },
    subContainerTwo: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gap: "2rem",
      backgroundColor: "#E1F4FF",
      padding: "1rem",
      textAlign: "center",
      borderRadius: "1rem",
      "& > div:nth-child(1)": {
        borderRight: "2px solid var(--color-primary)",
      },
      "& > div > h1": {
        color: "var(--color-primary)",
        fontWeight: "500",
      },
      "& > div > p": {
        color: "#4f4f4f",
        fontSize: "1.2rem",
        margin: "0.5rem",
      },
    },
    subContainerThree: {
      display: "grid",
      gridTemplateColumns: "23.5% 23.5% 23.5% 23.5%",
      gap: "1rem",
      padding: "1rem 0",
      textAlign: "left",
      height: "10.7rem",
      "@media (max-width: 550px)": {
        gridTemplateColumns: "auto auto",
        height: "auto",
      },
      "& > div": {
        backgroundColor: "#F2F2F2",
        borderRadius: "1rem",
        padding: "1rem",
      },
      "& > div:nth-child(1)": {
        backgroundColor: "var(--color-primary)",
        color: "white",
      },
      "& > div:nth-child(1) > p": {
        color:
          userDetails.themeDark === "dark"
            ? "white !important"
            : "white !important",
      },
      "& > div:nth-child(1) > h3": {
        color: "white",
      },
      "& > div > p": {
        fontSize: "1.2rem",
        margin: "0rem",
        color:
          userDetails.themeDark === "dark"
            ? "#4f4f4f !important"
            : "black !important",
        height: "3rem",
      },
      "& > div > h3": {
        fontSize: "1.8rem",
        fontWeight: "700",
        marginTop: "1rem",
        color: "black",
      },
    },
    barChartStyle: {
      "& > div": {
        width: "100%",
        "& > svg > g > rect": {
          fill: "#87C2E4",
        },
        "& > svg > g:nth-child(5)": {
          // display: "none",
        },
      },
    },
    barChartStyleChart: {
      position: "relative",
      top: "-4rem",
    },
    bodyContainer: {
      textAlign: "center",
      marginTop: "3rem",
      "& > p": {
        color:
          userDetails.themeDark === "dark"
            ? "white !important"
            : "#4f4f4f !important",
        margin: "1rem 0",
      },
    },
    // progressTracler
    mainBox: {
      margin: "2rem 0rem",
      padding: "1rem 0",
    },
    pieChartStyle: {
      width: "40%",
      "@media (max-width: 750px)": {
        width: "100%",
      },
      "@media (min-width: 770px) and (max-width: 1360px)": {
        width: "100%",
      },
      "& > div > svg > g > g > g > path:nth-child(1)": {
        fill: "#1E7BB9",
      },
      "& > div > svg > g > g > g > path:nth-child(2)": {
        fill: "#1EB91E",
      },
      "& > div > svg > g > g > g > path:nth-child(3)": {
        fill: "#F8C354",
      },
    },
    pieChartStyle1: {
      width: "40%",
      "@media (max-width: 750px)": {
        width: "100%",
      },
      "@media (min-width: 770px) and (max-width: 1360px)": {
        width: "100%",
      },
      "& > div > svg > g > g > g > path": {
        fill: "#1E7BB9",
      },
    },
    flexStyleResponsive: {
      display: "flex",
      marginTop: "2rem",
      "@media (max-width: 600px)": {
        display: "block",
        paddingBottom: "1rem",
      },
      "@media (min-width: 770px) and (max-width: 1111px)": {
        display: "block",
        height: "23rem",
        overflow: "auto",
        paddingBottom: "3rem",
      },
      "& > div:nth-child(2)": {
        display: "grid",
        alignItems: "center",
        gap: "3rem",
        margin: "auto",
        width: "60%",
        "@media (max-width: 750px)": {
          width: "100%",
        },
        "@media (min-width: 770px) and (max-width: 1360px)": {
          width: "100%",
        },
      },
    },
    progressBar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      gap: "1rem",
      flexWrap: "wrap-reverse",
      "& > p:nth-child(1)": {
        width: "82%",
        fontWeight: "700",
        overflowWrap: "break-word",
      },
      "& > p:nth-child(3)": {
        width: "4rem",
        fontWeight: "700",
      },
    },
    subHeading: {
      color:
        userDetails.themeDark === "dark" ? "#dddddd !important" : "#4f4f4f",
      fontSize: "1.8rem",
    },
    progressBarContainer: {
      width: "82%",
      "@media (min-width: 400px) and (max-width: 600px)": {
        width: "99%",
      },
    },
    breadcrumbNameStyle: {
      fontSize: "1.5rem!important",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "black !important",
      fontFamily: `myfontregular!important`,
    },
    root: {
      "& .super-app-theme--header": {
        backgroundColor: "#217B9F",
        fontSize: "18px",
        color: "rgb(255,255,255)",
        fontFamily: "nunito",
        fontWeight: "bold",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    ".MuiDataGrid-root": {
      fontSize: "14px",
      fontFamily: "nunito",
      fontWeight: 400,
      color: "black",
      cursor: "pointer",
    },
  }));
  const classes = useStyles();
  async function modifycolumns(data) {
    if (!data || data.length === 0) return;

    // Determine the maximum scores length
    const maxScoresLength = Math.max(
      ...data.map((item) => item?.scores?.length || 0)
    );

    // Add dynamic columns for attempts
    const dynamicColumns = Array.from(
      { length: maxScoresLength },
      (_, index) => ({
        field: `attempt${index + 1}`,
        headerName: `Attempt ${index + 1}`,
        flex: 1,
        headerClassName: "super-app-theme--header", // Apply header style
        cellClassName: classes.cellStyle,
        headerAlign: "center",
        align: "center",
        minWidth: 100,
      })
    );

    // Combine base columns with dynamic columns
    const updatedColumns = [
      {
        field: "col1",
        headerName: "Course Name",
        flex: 1,
        headerClassName: "super-app-theme--header", // Apply header style
        cellClassName: classes.cellStyle,
        minWidth: 150,
      },
      {
        field: "col2",
        headerName: "Assement Name",
        flex: 1,
        headerClassName: "super-app-theme--header", // Apply header style
        cellClassName: classes.cellStyle,

        minWidth: 150,
      },
      {
        field: "col3",
        headerName: "Start Date",
        flex: 1,
        headerClassName: "super-app-theme--header", // Apply header style
        cellClassName: classes.cellStyle,
        headerAlign: "center",
        align: "center",
        minWidth: 150,
      },
      {
        field: "col4",
        headerName: "Completed Date",
        flex: 1,
        headerClassName: "super-app-theme--header", // Apply header style
        cellClassName: classes.cellStyle,
        headerAlign: "center",
        align: "center",
        minWidth: 150,
      },
      ...dynamicColumns,
    ];

    setFinalColumns(updatedColumns);
  }
  async function mapData(data) {
    if (data.length > 0) {
      const trows = data.map((item, index) => {
        const row = {
          id: index,
          col1: item.topic_name,
          col2: item.otitle,
          col3: formatDate(item.submission_date),
          col4: formatDate(item.completion_date),
        };

        if (item.scores) {
          // Dynamically add score attempts to the row
          item.scores.forEach((score, scoreIndex) => {
            row[`attempt${scoreIndex + 1}`] = score;
          });
        }

        return row;
      });

      setRows(trows);
    }
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "Learning_Analytics",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }
  async function getStandAloneCourseScores() {
    setSpin(true);
    try {
      const requestBody = {
        org: config.aws_org_id,
        schema: config?.schema,
        ur_id: userDetails?.uData?.ur_id,
        oid: userDetails?.uData?.oid,
      };
      // Convert the request body to a JSON-encoded string
      const jsonString = JSON.stringify(requestBody);
      // Encode the JSON string in the query string
      const base64EncodedString = btoa(jsonString);
      // Construct the full URL with the encoded JSON string in the query parameter
      const reportInfo = `/getindividualcoursescores?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      // Make the API call using Amplify's API.get
      const users = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      if (users !== undefined && users !== null) {
        const userData = users.body;
        await modifycolumns(userData);
        await mapData(userData);
        setSpin(false);
      }
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }
  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  //score board completed =========================================
  const [data1, setData1] = useState([
    { label: "In progress", value: 0, color: "#1E7BB9" },
    { label: "Completed", value: 0, color: "#1EB91E" },
  ]);
  const { isLoading, error, data, refetch } = useQuery(
    ["getmdata"],
    async () => {
      //     var body = {
      //       ur_id: userDetails?.uData?.ur_id,
      //       tenant: userDetails?.uData?.oid,
      //     },
      //       apiname = "/getLeaderboardData";
      //     const response = await fetchData(body, apiname);
      //     return response;
      //   }
      // );
      const requestBody = {
        ur_id: userDetails?.uData?.ur_id,
        tenant: userDetails?.uData?.oid,
        schema: config?.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getLeaderboardData?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      return response;
    }
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [
          data,
          dashData,
          learningData,
          allcoursedata,
          timeSpentData,
        ] = await Promise.all([
          getMyTopics(userDetails),
          dashboard(userDetails),
          getLearningPathDetails(userDetails),
          getAllCourses(userDetails),
          timeSpent(userDetails),
        ]);
        setTotalCount(allcoursedata?.allcourses?.length);
        setTotalObject(allcoursedata?.objects?.length);
        setTotalLearning(learningData?.length);
        setTotalSession(dashData?.allSessions);
        setCompletedCount(data?.completedCourse?.length);
        setContinueLearningCount(data?.continueLearning?.length);
        setCompletedCourse(data?.completedCourse);
        data1[0].value = data?.continueLearning?.length;
        data1[1].value = data?.completedCourse?.length;
        setData1(data1);
        const totalHours = timeSpentData.body.reduce(
          (acc, entry) => acc + entry.time,
          0
        );
        setTotalTimeHours(Math.floor(totalHours / 3600));
        setTotalTimeMinutes(Math.ceil((totalHours % 3600) / 60));
        const updatedPData = timeSpentData.body.map((entry) => {
          const hours = entry.time / 3600;
          return `${hours.toFixed(2)}`;
        });
        const updatedXLabels = timeSpentData.body.map((entry) => {
          const date = new Date(entry.cdate);
          return date.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
          });
        });
        setPData(updatedPData);
        setXLabels(updatedXLabels);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [userDetails, data1]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch your data here
        const data = await getStandAloneCourseScores(); // Replace with your API call
        await modifycolumns(data);
        await mapData(data);
        setSpin(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSpin(false);
      }
    };
    fetchData();
  }, []);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      className={classes.breadcrumbNameStyle}
    >
      {`${userDetails?.languagejson?.LearningAnalytics?.Learning_Analytics ||
        "Learning Analytics"}`}
    </Typography>,
  ];
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1.2rem",
    },
  }));
  return (
    <>
      <Stack spacing={2}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              fontSize="large"
              style={{ color: "var(--color-primary)" }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className="flex-between" style={{ margin: "2rem 0rem 3rem" }}>
        <div className={classes.mainContainer}>
          <h3 style={{ fontWeight: "600" }} className="responsive">
            {isLoading ? (
              <Skeleton className={classes.loaderSkeleton} />
            ) : (
              <>{`${userDetails?.languagejson?.LearningAnalytics
                ?.Learning_Analytics || "Learning Analytics"}`}</>
            )}
          </h3>
        </div>
      </div>
      <div className={classes.subContainer}>
        <div item xs={6} className={classes.subSection}>
          <h3 style={{ fontWeight: "600" }} className="responsive">
            {`${userDetails?.languagejson?.LearningAnalytics?.Time_Spent ||
              "Time Spent"}`}
          </h3>
          <div
            className={classes.bodyContainer}
            style={{
              margin: "1rem 0rem",
              height: "35rem",
              textAlign: "center",
            }}
          >
            {loading ? (
              <>
                <p>{`${userDetails?.languagejson?.LearningAnalytics
                  ?.Total_Time_Spent_this_Week ||
                  "Total Time Spent this Week"}`}</p>
                <div className={classes.subContainerTwo}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton
                      variant="text"
                      width={50}
                      height={70}
                      animation="wave"
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton
                      variant="text"
                      width={50}
                      height={70}
                      animation="wave"
                    />
                  </div>
                </div>
                <div className={classes.barChartStyle}>
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={200}
                    style={{ margin: "4rem 0" }}
                    animation="wave"
                  />
                </div>
              </>
            ) : (
              <>
                <p>{`${userDetails?.languagejson?.LearningAnalytics
                  ?.Total_Time_Spent_this_Week ||
                  "Total Time Spent this Week"}`}</p>
                <div className={classes.subContainerTwo}>
                  <div>
                    <h1>{totalTimeHours}</h1>
                    <p>{`${userDetails?.languagejson?.LearningAnalytics?.Hour ||
                      "Hour"}`}</p>
                  </div>
                  <div>
                    <h1>{totalTimeMinutes}</h1>
                    <p>{`${userDetails?.languagejson?.LearningAnalytics
                      ?.Minutes || "Minutes"}`}</p>
                  </div>
                </div>
                {pData.length > 0 && xLabels.length > 0 && (
                  <div className={classes.barChartStyleChart}>
                    <BarChart
                      height={300}
                      series={[
                        {
                          data: pData,
                          id: "pvId",
                          stack: "total",
                        },
                      ]}
                      xAxis={[
                        {
                          data: xLabels,
                          scaleType: "band",
                        },
                      ]}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div item xs={6} className={classes.subSection}>
          {loading && (
            <div>
              <h3>{`${userDetails?.languagejson?.Course_View
                ?.My_Courses_Progress_Tracker || ""}`}</h3>
              <div className={classes.mainBox}>
                <div className={classes.subContainerThree}>
                  <div>
                    <p className={classes.subHeading}>{`${userDetails
                      ?.languagejson?.Course_View?.Total_Assigned_Courses ||
                      "Total Courses"}`}</p>
                    <h3>
                      <Skeleton
                        variant="rectangular"
                        width={"50%"}
                        height={20}
                        animation="wave"
                      />
                    </h3>
                  </div>
                  <div>
                    <p className={classes.subHeading}>{`${userDetails
                      ?.languagejson?.LearningAnalytics
                      ?.Total_Content_Materials ||
                      "Total Content Materials"}`}</p>
                    <h3>
                      <Skeleton
                        variant="rectangular"
                        width={"50%"}
                        height={20}
                        animation="wave"
                      />
                    </h3>
                  </div>
                  <div>
                    <p className={classes.subHeading}>{`${userDetails
                      ?.languagejson?.LearningAnalytics?.Total_Learning_Paths ||
                      "Total Learning Paths"}`}</p>
                    <h3>
                      <Skeleton
                        variant="rectangular"
                        width={"50%"}
                        height={20}
                        animation="wave"
                      />
                    </h3>
                  </div>
                  <div>
                    <p className={classes.subHeading}>{`${userDetails
                      ?.languagejson?.LearningAnalytics?.Total_Sessions ||
                      "Total Sessions"}`}</p>
                    <h3>
                      <Skeleton
                        variant="rectangular"
                        width={"50%"}
                        height={20}
                        animation="wave"
                      />
                    </h3>
                  </div>
                </div>
              </div>
              <div
                className={classes.flexStyleResponsive}
                style={{ marginTop: "3rem" }}
              >
                <div
                  className={classes.pieChartStyle1}
                  style={{ position: "relative", marginBottom: "2rem" }}
                >
                  <img src={EmptyChart} alt="Empty Chart" title="Empty Chart" />
                  <div
                    style={{
                      position: "absolute",
                      top: "8rem",
                      left: "7rem",
                      textAlign: "center",
                    }}
                  >
                    <p className={classes.subHeading}>{`${userDetails
                      ?.languagejson?.Home_Page?.No_Data || "No Data"}`}</p>
                    <BootstrapTooltip
                      title={`${userDetails?.languagejson?.Home_Page
                        ?.Contact_Admin_for_course_information ||
                        "Contact Admin for course information"}`}
                    >
                      <InfoOutlinedIcon
                        style={{
                          fontSize: "2rem",
                          color: "#1974b5",
                          marginTop: "5px",
                        }}
                      />
                    </BootstrapTooltip>
                  </div>
                </div>
                <div>
                  <div className={classes.progressBar}>
                    <p>{`${userDetails?.languagejson?.Home_Page?.In_Progress ||
                      "In progress"}`}</p>
                    <div className={classes.progressBarContainer}>
                      <BorderLinearProgressEmpty
                        variant="determinate"
                        value={(continuelearningCount * 100) / totalCount}
                      />
                    </div>
                    <p className={classes.subHeading}>
                      <Skeleton
                        variant="rectangular"
                        width={"50%"}
                        height={20}
                        animation="wave"
                      />{" "}
                    </p>
                  </div>
                  <div className={classes.progressBar}>
                    <p>{`${userDetails?.languagejson?.Home_Page?.Completed ||
                      "Completed"}`}</p>
                    <div className={classes.progressBarContainer}>
                      <BorderLinearProgressEmpty
                        variant="determinate"
                        value={(completedCount * 100) / totalCount}
                      />
                    </div>
                    <p className={classes.subHeading}>
                      <Skeleton
                        variant="rectangular"
                        width={"50%"}
                        height={20}
                        animation="wave"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && (
            <div>
              <h3>{`${userDetails?.languagejson?.Course_View
                ?.My_Courses_Progress_Tracker ||
                "My Courses Progress Tracker"}`}</h3>
              <div className={classes.mainBox}>
                <div className={classes.subContainerThree}>
                  <div>
                    <p className={classes.subHeading}>{`${userDetails
                      ?.languagejson?.Course_View?.Total_Assigned_Courses ||
                      "Total Courses"}`}</p>
                    <h3>{totalCount || "-"}</h3>
                  </div>
                  <div>
                    <p className={classes.subHeading}>{`${userDetails
                      ?.languagejson?.LearningAnalytics
                      ?.Total_Content_Materials ||
                      "Total Content Materials"}`}</p>
                    <h3>{totalObjects || "-"}</h3>
                  </div>
                  <div>
                    <p className={classes.subHeading}>{`${userDetails
                      ?.languagejson?.LearningAnalytics?.Total_Learning_Paths ||
                      "Total Learning Paths"}`}</p>
                    <h3>{totalLearning || "-"}</h3>
                  </div>
                  <div>
                    <p className={classes.subHeading}>{`${userDetails
                      ?.languagejson?.LearningAnalytics?.Total_Sessions ||
                      "Total Sessions"}`}</p>
                    <h3>{totalSession || "-"}</h3>
                  </div>
                </div>
                {continuelearningCount + completedCount === 0 ? (
                  <div className={classes.flexStyleResponsive}>
                    <div
                      className={classes.pieChartStyle1}
                      style={{ position: "relative", marginBottom: "2rem" }}
                    >
                      <img
                        src={EmptyChart}
                        alt="Empty Chart"
                        title="Empty Chart"
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "8rem",
                          left: "7rem",
                          textAlign: "center",
                        }}
                      >
                        <p className={classes.subHeading}>{`${userDetails
                          ?.languagejson?.Home_Page?.No_Data || "No Data"}`}</p>
                        <BootstrapTooltip
                          title={`${userDetails?.languagejson?.Home_Page
                            ?.Contact_Admin_for_course_information ||
                            "Contact Admin for course information"}`}
                        >
                          <InfoOutlinedIcon
                            style={{
                              fontSize: "2rem",
                              color: "#1974b5",
                              marginTop: "5px",
                            }}
                          />
                        </BootstrapTooltip>
                      </div>
                    </div>
                    <div>
                      <div className={classes.progressBar}>
                        <p>{`${userDetails?.languagejson?.Home_Page
                          ?.In_Progress || "In progress"}`}</p>
                        <div className={classes.progressBarContainer}>
                          <BorderLinearProgressEmpty
                            variant="determinate"
                            value={(cncount * 100) / (cncount + cmpcount)}
                          />
                        </div>
                        <p className={classes.subHeading}>-</p>
                      </div>
                      <div className={classes.progressBar}>
                        <p>{`${userDetails?.languagejson?.Home_Page
                          ?.Completed || "Completed"}`}</p>
                        <div className={classes.progressBarContainer}>
                          <BorderLinearProgressEmpty
                            variant="determinate"
                            value={(cmpcount * 100) / (cncount + cmpcount)}
                          />
                        </div>
                        <p className={classes.subHeading}>-</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classes.flexStyleResponsive}>
                    <div className={classes.pieChartStyle}>
                      <PieChart
                        series={[
                          {
                            data: data1,
                            cx: 100,
                            cy: 105,
                            innerRadius: 60,
                            outerRadius: 90,
                          },
                        ]}
                        height={230}
                        legend={{ hidden: true }}
                      />
                    </div>
                    <div>
                      <div className={classes.progressBar}>
                        <p>{`${userDetails?.languagejson?.Home_Page
                          ?.In_Progress || "In progress"}`}</p>
                        <div className={classes.progressBarContainer}>
                          <BorderLinearProgress1
                            variant="determinate"
                            value={(continuelearningCount * 100) / totalCount}
                          />
                        </div>
                        <p>{continuelearningCount}</p>
                      </div>
                      <div className={classes.progressBar}>
                        <p>{`${userDetails?.languagejson?.Home_Page
                          ?.Completed || "Completed"}`}</p>
                        <div className={classes.progressBarContainer}>
                          <BorderLinearProgress2
                            variant="determinate"
                            value={(completedCount * 100) / totalCount}
                          />
                        </div>
                        <p>{completedCount}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classes.bodyContainer}>
        <Carousel
          heading={`${userDetails?.languagejson?.Home_Page?.Completed_Courses ||
            "Completed Courses"}`}
          countDisplay={true}
          cardData={completedCourse}
          loading={loading}
          classDefine="item-4"
        />
      </div>

      {!spin && rows.length <= 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <img src={NothoingHereYet} alt="NothingHereYet" width="30%" />
        </div>
      ) : (
        <>
          {spin ? (
            <Backdrop className={classes.backdrop} open={spin}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <div
              className="carousel-container"
              style={{ "margin-top": "2rem" }}
            >
              <h1 className="carousel-heading">
                {userDetails?.languagejson?.Home_Page?.Score_Board ||
                  "Score Board"}
              </h1>
              <DataGrid
                rows={rows}
                columns={finalColumns}
                className={classes[".MuiDataGrid-root"]}
                components={{
                  Toolbar: CustomToolbar,
                }}
                pageSize={5}
                checkboxSelection
                disableSelectionOnClick
                autoHeight
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
export default LearningAnalytics;
