import React, { useState, useEffect } from "react";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { API } from "aws-amplify";
import {
  Inject,
  ScheduleComponent,
  Day,
  Month,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
import "./calendar.scss";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

import { Stack, Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import moment from "moment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import Modal from "@mui/material/Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "start",
    marginLeft: "50px",
  },
  paperEvents: {
    padding: theme.spacing(2),
    textAlign: "start",
  },
  calenderSection: {
    display: "flex",
    gap: "4rem",
    paddingTop: "3rem",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      display: "grid",
      paddingLeft: 0,
    },
  },
  calenderContent: {
    width: "80%",
    display: "grid",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      width: "100%",
      display: "grid",
    },
  },
  eventsContent: {
    width: "55%",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      marginTop: "4rem",
      width: "100%",
    },
  },
  breadcrumbNameStyle: {
    fontSize: "1.5rem!important",
    fontFamily: `myfontregular!important`,
  },
}));
function Calender() {
  const classes = useStyles();
  let userDetails = useSelector(authData);
  const [events, setevents] = useState([]);
  const [upEvents, setupEvents] = useState([]);

  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [eventOpen, setEventOpen] = useState({});

  const [allEvents, setAllEvents] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    getEvents();
    return () => {};
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    padding: "2rem",
    borderRadius: "8px",
  };

  async function getEvents() {
    try {
      const requestBody = {
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        st_id: userDetails.uData.st_id,
        schema: config.schema,
        groups: userDetails?.uData?.gid,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getEvents?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      convertEventData(response.events);
      setAllEvents(response.events);
    } catch (error) {
      console.error(error);
    }
  }
  function convertEventData(data) {
    let upEvents = data;
    filter(upEvents);
    function filter(data) {
      let m = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        let res = new Date(Number(data[i].start));
        let d = new Date();

        if (
          res.getFullYear() == d.getFullYear() &&
          res.getMonth() >= d.getMonth() &&
          res.getDate() >= d.getDate()
        ) {
          let obj = {
            title: data[i].title,
            desc: data[i].desc,
            month: m[res.getMonth()],
            day: res.getDate(),
            link: data[i].link,
          };
          temp.push(obj);
        }
      }
      setupEvents(temp);
    }
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      let res = new Date(Number(data[i].start));
      let obj = {
        title: data[i].title,
        startTime: res,
        endTime: res,
        sid: data[i].sid,
        sdesc: data[i].desc,
        link: data[i]?.link,
      };
      temp.push(obj);
    }
    setevents(temp);
  }
  let fields = {
    subject: { name: "title", default: "Event" },
    startTime: { name: "startTime" },
    endTime: { name: "endTime" },
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      style={{
        color: "#777",
        fontSize: "1.5rem",
        textDecoration: "none",
        cursor: "context-menu",
      }}
    >
      {`${userDetails?.languagejson?.header?.Calendar || "Calendar"}`}
    </Typography>,
  ];

  const handleClose = () => setOpen(false);
  function EventOpen(args) {
    args.cancel = true;
    setEventOpen(args.event);
    setOpen(true);
  }

  return (
    <>
      <Stack spacing={2} style={{ marginBottom: "2rem" }}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              fontSize="large"
              style={{ color: "var(--color-primary)" }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div>
        <div className={classes.calenderSection}>
          <div
            className={classes.calenderContent}
            role="main"
            aria-label="Calendar Section"
          >
            <h3
              component="h1"
              variant="h4"
              style={{
                marginLeft: "-0px",
                fontWeight: "600",
                fontSize: "2rem",
                paddingBottom: "18px",
                fontFamily: `myfontregular`,
              }}
              className="titleStyle"
            >
              {`${userDetails?.languagejson?.header?.Calendar || "Calendar"}`}
            </h3>
            <Paper elevation={8}>
              <ScheduleComponent
                currentView="Month"
                width="100%"
                readonly={true}
                fontFamily="myfontregular"
                eventClick={(e) => EventOpen(e)}
                eventSettings={{
                  dataSource: events,
                  fields: fields,
                  enableTooltip: false,
                }}
              >
                <Inject
                  style={{ fontFamily: `myfontregular` }}
                  services={[Month, Day, Week, WorkWeek]}
                />
              </ScheduleComponent>
            </Paper>
          </div>
          <div
            className={classes.eventsContent}
            role="main"
            aria-label="Upcoming Events Section"
          >
            <h3
              style={{
                marginLeft: "-0px",
                fontWeight: "600",
                fontSize: "2rem",
                paddingBottom: "18px",
                fontFamily: `myfontregular`,
              }}
              className="titleStyle"
            >
              {`${userDetails?.languagejson?.Calendar?.Upcoming_events ||
                "Upcoming events"}`}
            </h3>
            <Paper className={classes.paperEvents} elevation={8}>
              {upEvents.length === 0 || upEvents === undefined ? (
                <p style={{ fontSize: "16px", textAlign: "center" }}>
                  {" "}
                  {`${userDetails?.languagejson?.Calendar?.Nothing_here_yet ||
                    "Nothing here yet"}`}
                </p>
              ) : (
                upEvents.map((event) => (
                  <div
                    style={{ marginTop: "30px", fontFamily: `myfontregular` }}
                  >
                    <div>
                      <div className="iconTop">{event.month}</div>
                      <div className="iconBottom">{event.day}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "medium",
                        whiteSpace: "normal",
                        minHeight: "10px",
                        marginLeft: "61px",
                        fontFamily: `myfontregular`,
                      }}
                    >
                      <div style={{ marginTop: "-22px" }}>{event.title}</div>
                      <div style={{ fontSize: "12px" }}>{event.desc}</div>
                      <div style={{ fontSize: "12px" }}>{event.sid}</div>
                      {event.link && (
                        <div>
                          <span>
                            Link:{" "}
                            <a
                              href={event.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {event.link}
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                    <hr className="eventsDivider" />
                  </div>
                ))
              )}
            </Paper>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <h2
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ marginBottom: "1rem" }}
              >
                {eventOpen.title}
              </h2>
              <div
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className="divPadding"
              >
                {eventOpen.sdesc}
              </div>
              <div id="modal-modal-description" sx={{ mt: 2 }}>
                At : {moment(eventOpen.startTime).format("DD-MM-YYYY hh:mm a")}
              </div>
              {eventOpen.link && (
                <div>
                  <span>
                    Link:{" "}
                    <a href={eventOpen.link} target="_blank" rel="noreferrer">
                      {eventOpen.link}
                    </a>
                  </span>
                </div>
              )}
              <div
                className="buttonsSpace"
                style={{ display: "flex", gap: "2rem", padding: "1rem" }}
              >
                {eventOpen.sid !== null ? (
                  <Button
                    onClick={() => {
                      history.push(`/session?sid=${eventOpen.sid}`);
                      //dispatch(session(item));
                    }}
                    variant="contained"
                  >
                    View Session
                  </Button>
                ) : null}
                <Button onClick={() => setOpen(false)} variant="outlined">
                  Close
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}
export default Calender;
