import { Avatar, makeStyles, CircularProgress, Box } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import ProfilePopUp from "../../components/ProfilePopUp/ProfilePopUp";
import config from "../../aws-exports";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import "../../components/ModalPopUp/certificate.scss";
import ENABLED from "../../assets/ENABLED.png";
import EXCELSOFT from "../../assets/EXCELSOFT.png";
import AXIATA from "../../assets/AXIATA.png";
import NSECORPCERT from "../../assets/ONDCLogo.png";
import NSECORP from "../../assets/NSECORP.png";
import { ReactComponent as Edit } from "../../assets/edit_black_24dp.svg";
import BHODICO from "../../assets/BODHICO.png";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import noThingIsHere from "../../assets/Nothing here yet.gif";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import editStyle from "./EditProfile/MyProfile.module.scss";
import DarkLightMode from "../../components/DarkLightMode/DarkLightMode";
import {
  getUserDetails,
  getUserCertificate,
  getUserCompetencies,
} from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import SessionTimeOut from "../../components/Sessions/SessionTimeout";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";
import { Divider, Paper, Typography, styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const MyProfile = ({ setTheme, theme }) => {
  const userDetails = useSelector(authData);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    root121: {
      width: "300px",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      "@media (max-width: 625px)": {
        margin: "auto",
      },
    },
    certificateContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "3rem",
      marginLeft: "3rem",
      cursor: "pointer",
      "@media (max-width: 430px)": {
        marginLeft: 0,
      },
    },
    myProfileSection: {
      padding: "30px",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#272C38 !important" : "#fff",
      borderRadius: "5px",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "inset 1px 1px 3px 1px rgb(56 63 81) !important"
          : "0px 0px 10px 0px rgba(0,0,0,0.2)",
      "@media (max-width: 430px)": {
        padding: "1rem 1rem 2rem",
      },
    },
    myProfileSectionLang: {
      width: "50%",
      "@media (max-width: 430px)": {
        padding: "1rem 1rem 2rem",
      },
      "@media (max-width: 780px)": {
        width: "100%",
      },
    },
    certificateSection: {
      padding: "30px 30px 80px 30px",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#272C38 !important" : "#fff",
      borderRadius: "5px",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "inset 1px 1px 3px 1px rgb(56 63 81) !important"
          : "0px 0px 10px 0px rgba(0,0,0,0.2)",
      "@media (max-width: 430px)": {
        padding: "1rem 1rem 2rem",
      },
    },
    mainbookdiv: {
      display: "flex",
      gap: "20px",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    booksdiv: {
      padding: "15px",
      width: "25rem",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#272C38 !important" : "#fff",
      borderRadius: "5px",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "inset 1px 1px 3px 1px rgb(56 63 81) !important"
          : "0px 0px 10px 0px rgba(0,0,0,0.2)",
      "@media (max-width: 430px)": {
        padding: "1rem 1rem 2rem",
      },
    },
    profileSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "@media (max-width: 625px)": {
        display: "grid",
        gap: "3rem",
        margin: 0,
        textAlign: "center",
        justifyContent: "center",
      },
    },
    profileSectionFlex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 625px)": {
        display: "block",
      },
    },
    profileSectionEdit: {
      display: "flex",
      alignItems: "center",
      marginBottom: "2rem",
      marginLeft: "3rem",
      "@media (max-width: 430px)": {
        display: "block",
        marginLeft: 0,
      },
    },
    profileName: {
      marginLeft: "3rem",
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
      "@media (max-width: 625px)": {
        marginLeft: 0,
        marginTop: "2rem",
        textAlign: "center",
      },
    },
    mainHeader1: {
      margin: "5rem 0rem 2.5rem",
      fontSize: "2.5rem",
      fontWeight: "700",
      "@media (max-width: 375px)": {
        fontSize: "2rem",
      },
    },
    mainHeader: {
      marginBottom: "2.5rem",
      fontSize: "2rem",
      fontWeight: "600",
      "@media (max-width: 375px)": {
        marginBottom: "2rem",
        marginLeft: "1rem",
      },
    },
    imageResponsive: {
      width: "30rem",
      "@media (max-width: 605px)": {
        width: "-webkit-fill-available",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    iconView: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      color: userDetails.themeDark === "dark" ? "white" : "black",
      "& > div": {
        display: "block!important",
        backgroundColor: "var(--color-primary)!important",
        padding: "0.5rem 2rem!important",
        borderRadius: "5px",
        color: "white",
      },
    },
    profileLanguageSection: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gap: "4rem",
      "@media (max-width: 730px)": {
        gridTemplateColumns: "auto",
      },
    },
    competencySection: {
      display: "flex",
      alignItems: "center",
      gap: "3rem",
      flexWrap: "wrap",
      justifyContent: "start",
    },
    competencySectionPaper: {
      width: "30rem",
      boxShadow: "#0003 0px 0px 10px 0px!important",
      cursor: "pointer",
    },
    competencySectionComplete: {
      padding: "1rem",
      boxShadow: "#0003 0px -1px 2px 0px",
      borderRadius: "1rem 1rem 0rem 0rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "white",
      "& > svg": {
        fontSize: "2rem",
        color: "var(--color-primary)",
      },
      "& > div": {
        display: "flex",
        gap: "2rem",
        alignItems: "center",
        "& > svg": {
          width: "3rem",
          height: "3rem",
        },
        "& > h4": {
          color: "var(--color-primary)",
        },
      },
    },
    competencySectionNotComplete: {
      padding: "1rem",
      boxShadow: "#0003 0px -1px 2px 0px",
      borderRadius: "1rem 1rem 0rem 0rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#eeeeee",
      opacity: 0.7,
      "& > svg": {
        fontSize: "2rem",
        color: "gray",
      },
      "& > div": {
        display: "flex",
        gap: "2rem",
        alignItems: "center",
        "& > svg": {
          width: "3rem",
          height: "3rem",
        },
        "& > h4": {
          color: "gray",
        },
      },
    },
    skillSection: {
      width: "100%",
      display: "flex",
      gap: "2rem",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "2rem 0rem",
      "& > p:nth-child(2)": {
        fontSize: "14px",
        color: "var(--color-primary)",
        border: "1px solid var(--color-primary)",
        padding: "1px 5px",
        borderRadius: "3rem",
        backgroundColor: "white",
      },
      "& > p:nth-child(1)": {
        margin: 0,
      },
      "& > span": {
        width: "80%",
      },
    },
  }));
  const classes = useStyles();
  const [certPopUp, setCertPopUp] = useState(false);
  const [certificateList, setCertificateList] = useState([]);
  const [open, setOpen] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [competencies, setCompetencies] = useState([]);
  const [active, setActive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [sindex, setSindex] = useState("");
  const location = useLocation();
  const key = location.state?.key;
  const { data: profileData, isLoading: spin } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserDetails(userDetails),
  });
  const { data: certificates, isLoading: spinCert } = useQuery({
    queryKey: ["cert"],
    queryFn: () => getUserCertificate(userDetails),
  });
  const { data: competency, isLoading: spinComp } = useQuery({
    queryKey: ["comp"],
    queryFn: () => getUserCompetencies(userDetails),
  });
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      let filterstoredata = { ...userDetails };
      delete filterstoredata.filter;
      dispatch(awsSignIn(filterstoredata));
      getUserCertificate(userDetails);
      getUserCompetencies(userDetails);
    }
  }, []);
  useEffect(() => {
    if (competency && competency.length > 0) {
      const newCompetencies = competency.map((compet) => {
        const progress =
          compet?.skill && compet.skill.length !== 0
            ? parseFloat(
                ((compet.comskills.length / compet.skill.length) * 100).toFixed(
                  1
                )
              )
            : 0;
        return {
          ...compet,
          progress: progress,
        };
      });
      setCompetencies(newCompetencies);
    }
  }, [competency]);
  useEffect(() => {
    if (profileData) {
      let userData = { ...userDetails };
      userData.uData = profileData;
      console.log("profileData==", profileData.books);
      dispatch(awsSignIn(userData));
    }
  }, []);
  let languages = [
    { id: "1", lang_name: "English", lang_translation: "English" },
    { id: "2", lang_name: "Kannada", lang_translation: "ಕನ್ನಡ" },
    { id: "3", lang_name: "Hindi", lang_translation: "हिन्दी" },
    { id: "4", lang_name: "Marathi", lang_translation: "मराठी" },
    { id: "5", lang_name: "Tamil", lang_translation: "தமிழ்" },
    { id: "6", lang_name: "Gujarati", lang_translation: "ગુજરાતી ભાષા" },
    { id: "7", lang_name: "Telugu", lang_translation: "తెలుగు" },
    { id: "8", lang_name: "Bengali", lang_translation: "বাংলা" },
    { id: "9", lang_name: "Assamese", lang_translation: "অসমীয়া" },
    { id: "10", lang_name: "Oriya", lang_translation: "ଓଡ଼ିଆ" },
    { id: "11", lang_name: "Malayalam", lang_translation: "മലയാളം" },
    { id: "12", lang_name: "Punjabi", lang_translation: "ਪੰਜਾਬੀ" },
  ];
  function getLanguageById(id) {
    let obj = languages.find((language) => language.id === id);
    return obj?.lang_translation;
  }
  let profile_Certificates = useRef(null);
  const scrollDown = () => {
    const section = document.getElementById("hereatcert");
    if (section) {
      const offset = section.getBoundingClientRect().top + window.scrollY - 80;
      window.scroll({
        behavior: "smooth",
        top: offset,
      });
    }
  };
  useEffect(() => {
    if (certificates) {
      const params = new URLSearchParams(window.location.search);
      if (key === "giz") {
        scrollDown();
      }
      setCertificateList(certificates);
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", certificates.Policy);
      Cookies.set("CloudFront-Signature", certificates.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", certificates.KeyPairId);
    }
  }, [certPopUp]);
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 6,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      background: "linear-gradient(1deg, #ededed 0%, #e1e1e1 51%)",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: "var(--color-button-background)",
    },
  }));
  return (
    <div>
      <SessionTimeOut active={active} setActive={setActive} />
      <h2 variant="h3" className={classes.mainHeader1}>
        {spin ? (
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "10rem" }} />
        ) : (
          `${userDetails?.languagejson?.My_Profile?.My_Profile || "My Profile"}`
        )}
      </h2>
      <div
        className={classes.myProfileSection}
        role="main"
        aria-label="My Profile Section"
      >
        <div className={classes.profileSection}>
          <div className={classes.profileSectionFlex}>
            <Avatar
              sizes="large"
              className={classes.large}
              src={`https://${
                config.DOMAIN
              }/${config.aws_org_id.toLowerCase()}-resources/images/profile-images/${
                profileData?.avtid
              }.png`}
            />
            <div className={classes.profileName}>
              <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>
                {spin ? (
                  <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
                ) : (
                  `${profileData?.first_name} ${profileData?.last_name} `
                )}
              </h3>
              <h4 style={{ fontWeight: "400" }}>{profileData?.emailid}</h4>
              <h4 style={{ fontWeight: "400" }}>
                {userDetails && userDetails.uData && userDetails.uData.did}
              </h4>
            </div>
          </div>
          <div>
            <button
              className={editStyle.btn_color}
              onClick={() => {
                history.push({
                  pathname: "/editProfile",
                });
              }}
            >
              <div style={{ display: "flex" }}>
                <Edit
                  style={{ fill: "white", width: "20px", margin: "-3px 3px" }}
                />
                <p>
                  {" "}
                  {`${userDetails?.languagejson?.My_Profile?.Edit_profile ||
                    "Edit profile"}`}{" "}
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className={classes.profileLanguageSection}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <div>
            <h2 variant="h3" className={classes.mainHeader1}>
              {spin ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "3rem", width: "10rem" }}
                />
              ) : (
                `${userDetails?.languagejson?.My_Profile?.Theme_Changer ||
                  "Theme Changer"}`
              )}
            </h2>
            <div
              className={classes.myProfileSection}
              role="main"
              aria-label="My Profile Section"
            >
              <h2 variant="h3" className={classes.mainHeader}>
                {`${userDetails?.languagejson?.My_Profile?.Choose_your_Theme ||
                  "Choose your Theme"}`}
              </h2>
              <div className={classes.profileSection}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <DarkLightMode setTheme={setTheme} theme={theme} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 variant="h3" className={classes.mainHeader1}>
              {spin ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "3rem", width: "10rem" }}
                />
              ) : (
                `${userDetails?.languagejson?.My_Profile?.Change_Language ||
                  "Language Preference"}`
              )}
            </h2>
            <div
              className={classes.myProfileSection}
              role="main"
              aria-label="My Profile Section"
              style={{ height: "19rem" }}
            >
              <div
                className={classes.myProfileSectionLang}
                role="main"
                aria-label="My Profile Section"
              >
                <h2
                  variant="h3"
                  className={classes.mainHeader}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {`${userDetails?.languagejson?.My_Profile
                    ?.Select_your_Language || "Select your Language"}`}
                  <LanguageOutlinedIcon
                    style={{
                      fontSize: "2.5rem",
                      marginLeft: "1rem",
                      color: "gray",
                    }}
                  />
                </h2>
                <div className={classes.iconView}>
                  <LanguageSelect />
                  {getLanguageById(userDetails.uData.lang)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {config?.competency ? (
        spinComp ? (
          <Skeleton variant="text" sx={{ fontSize: "3rem", width: "10rem" }} />
        ) : (
          <div
            className={classes.myProfileSection}
            role="main"
            aria-label="My Profile Section"
            style={{ margin: "4rem 0rem 0rem" }}
          >
            <h2 variant="h3" className={classes.mainHeader}>
              {`${userDetails?.languagejson?.My_Profile
                ?.View_status_of_all_competencies ||
                "View status of all competencies"}`}
            </h2>
            <div className={classes.profileSection}>
              <div className={classes.competencySection}>
                {competencies.map((competency, index) => (
                  <Paper
                    className={classes.competencySectionPaper}
                    onClick={() => {
                      history.push({
                        pathname: "/competency",
                        state: { competency: competency },
                      });
                    }}
                  >
                    <div key={index} style={{ padding: "2rem 2rem 0rem" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4>{competency?.title}</h4>
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "4.5rem",
                              height: "4.5rem",
                            }}
                          >
                            <CircularProgress
                              variant="determinate"
                              value={competency?.progress}
                              size={"4.5rem"}
                              thickness={3}
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                color: "#dfdfdf",
                              }}
                            />
                            <CircularProgress
                              variant="determinate"
                              value={competency?.progress}
                              size={"4.5rem"}
                              thickness={4}
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                color: "#48bc48",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                width: "4.5rem",
                                height: "4.5rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                component="div"
                                color="textSecondary"
                                style={{
                                  color:
                                    userDetails.themeDark === "dark"
                                      ? "white"
                                      : "black",
                                }}
                              >
                                {`${competency?.progress}%`}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Divider
                        orientation="horizontal"
                        style={{ height: "1rem" }}
                      />
                      <div className={classes.skillSection}>
                        <p>Skills</p>
                        <p>
                          {(competency?.comskills &&
                            competency.comskills.length) ||
                            0}
                          /{(competency?.skill && competency.skill.length) || 0}
                        </p>
                        <BorderLinearProgress
                          variant="determinate"
                          value={competency?.progress}
                        />
                      </div>
                    </div>
                  </Paper>
                ))}
              </div>
            </div>
          </div>
        )
      ) : null}
      {spinCert ? (
        <div>
          <h2 variant="h3" className={classes.mainHeader1}>
            {spin ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "3rem", width: "10rem" }}
              />
            ) : (
              `${userDetails?.languagejson?.My_Profile?.Certificates ||
                "Certificates"}`
            )}
          </h2>
          <div
            role="main"
            aria-label="Certificates Section"
            className={classes.myProfileSection}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={noThingIsHere}
                alt="Nothing is here yet"
                title="Nothing is here yet"
                className={classes.imageResponsive}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          {config?.certificates && (
            <div>
              <div id="hereatcert" ref={profile_Certificates}></div>
              <div>
                <h2 variant="h3" className={classes.mainHeader1}>
                  {spin ? (
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "3rem", width: "10rem" }}
                    />
                  ) : (
                    `${userDetails?.languagejson?.My_Profile?.Certificates ||
                      "Certificates"}`
                  )}
                </h2>
                <div className={classes.certificateSection}>
                  <h2 variant="h3" className={classes.mainHeader}>
                    {" "}
                    {`${userDetails?.languagejson?.My_Profile?.Certificates ||
                      "Certificates"}`}{" "}
                  </h2>
                  <div className={classes.certificateContainer}>
                    {spinCert ? (
                      <></>
                    ) : (
                      <>
                        <>
                          {certPopUp ? (
                            <>
                              <ProfilePopUp
                                open={open}
                                setOpen={setOpen}
                                profileDetails={profileDetails}
                                certificateList={certificateList}
                                sindex={sindex}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                          }}
                        >
                          {certificates?.map((item, idx) => (
                            <div
                              className="container-1"
                              key={idx + "certificate"}
                              onClick={() => {
                                setOpen(true);
                                setCertPopUp(true);
                                setSindex(idx);
                                setProfileDetails({
                                  ...item,
                                  name: userDetails.name,
                                });
                              }}
                            >
                              <div className="img-c">
                                <img
                                  src={
                                    config.aws_org_id === "ENABLED"
                                      ? ENABLED
                                      : config.aws_org_id === "EXCELEARN"
                                      ? EXCELSOFT
                                      : config.aws_org_id === "NSECORP"
                                      ? NSECORPCERT
                                      : config.aws_org_id === "BODHICO"
                                      ? BHODICO
                                      : config.aws_org_id === "NSEKAR"
                                      ? NSECORP
                                      : AXIATA
                                  }
                                  alt="logo"
                                  height="30"
                                />
                              </div>
                              <div className="org-container-1">
                                <p>Certificate awarded to</p>
                              </div>
                              <h1 className="name-1">
                                {userDetails?.uData?.last_name === undefined ||
                                userDetails?.uData?.last_name === null
                                  ? userDetails?.uData?.first_name
                                  : userDetails?.uData?.first_name +
                                    " " +
                                    userDetails?.uData?.last_name}
                              </h1>
                              <p className="complete">
                                for successfully completing
                              </p>
                              <div className="title-container-1">
                                <h2 className="title-1">
                                  {item?.ttitle !== undefined
                                    ? item?.ttitle
                                    : item?.ttitle}
                                </h2>
                              </div>
                              <p className="date-1">
                                Issued on{" "}
                                {item?.compdate !== undefined &&
                                  moment(new Date(item?.compdate)).format(
                                    "DD MMM YYYY"
                                  )}
                              </p>
                            </div>
                          ))}
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {profileData?.books && (
        <h2 variant="h3" className={classes.mainHeader1}>
          Books
        </h2>
      )}
      {profileData?.books && (
        <div className={classes.mainbookdiv}>
          {profileData.books?.map((item) => (
            <div className={classes.booksdiv}>
              <h4 variant="h3" className={classes.mainHeader}>
                {" "}
                {item}
              </h4>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default MyProfile;
