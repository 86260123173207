import React, { useState, useEffect, useRef } from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
  Button,
  Paper,
  makeStyles,
  Typography,
  Backdrop,
  CircularProgress,
  Breadcrumbs,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import "./Course.scss";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import CourseCard from "../../components/Cards/CourseCard/CourseCard";
import CircularProgressNugget from "../../components/CircularProgress/CircularProgressNugget";
import {
  TransitionsModal,
  ObjectPopUp,
  RatingPopUp,
  StyledButton,
} from "../../components/ModalPopUp/ModalPopUp";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
import config from "../../aws-exports";
import { Link, useHistory, useLocation } from "react-router-dom";
import Renderimage from "../../components/RenderImage/Renderimages";
import NuggetsLoader from "../../components/SkeletonLoader/NuggetsLoader";
import Cookies from "js-cookie";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { GridWrapper } from "./Course.Styled";
import { SCORM } from "pipwerks-scorm-api-wrapper";
import FeedbackPopUp from "../../components/ModalPopUp/FeedbackPopUp";
import SessionTimeOut from "../../components/Sessions/SessionTimeout";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    "& > div": {
      "@media (max-width: 800px)": {
        gridColumnGap: 0,
      },
      "& > div": {
        "@media (max-width: 800px)": {
          padding: "10px",
          gridColumn: "1/3!important",
        },
      },
    },
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "left",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  paperModules: {
    padding: theme.spacing(3),
    textAlign: "left",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    gridColumn: "1/3 !important",
  },
  body: {},
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "37px",
    margin: "2rem 1rem 1rem",
    flexDirection: "row",
  },
  paper1: {
    padding: theme.spacing(3),
    textAlign: "left",
    backgroundColor: "white",
    cursor: "pointer",
  },
  paper2: {
    textAlign: "left",
    backgroundColor: "white",
  },
  paper3: {
    textAlign: "left",
    maxHeight: "500px",
    backgroundColor: "white",
  },
  paper4: {
    textAlign: "left",
    backgroundColor: "white",
    width: "20vw",
  },
  outline: {
    fontSize: "3rem",
    fill: "none",
    stroke: "#646464",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  filled: {
    fontSize: "3rem",
    fill: "#8A7316",
    stroke: "#8A7316",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  bookmarkIconResponsive: {
    cursor: "pointer",
    marginLeft: "1rem",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    display: "flex",
    "&:focus": {
      border: "1px solid black",
    },
    "@media (max-width: 800px)": {
      marginLeft: 0,
    },
  },
  iconResponsive: {
    fill: "#777",
    fontSize: "2.5rem",
    marginRight: ".5rem",
    marginLeft: "2.5rem",
    "@media (max-width: 400px)": {
      marginLeft: "0.8rem",
    },
  },
  iconResponsiveRating: {
    marginRight: "2.5rem",
    fontFamily: `myfontregular`,
    "@media (max-width: 400px)": {
      marginRight: "0.8rem",
      fontSize: "1.3rem",
    },
  },
  textResponsive: {
    "@media (max-width: 400px)": {
      fontSize: "1.3rem",
    },
  },
  flexHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 400px)": {
      display: "block",
    },
  },
  timeHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: "4rem",
    fontFamily: `myfontregular`,
    "@media (max-width: 1205px)": {
      padding: "0.5rem 1rem!important",
      justifyContent: "end",
    },
  },
  textSection: {
    fontSize: "1.6rem",
    textAlign: "left",
    fontFamily: `myfontregular`,
    marginRight: "2rem",
    wordBreak: "break-word",
    "@media (max-width: 800px)": {
      marginRight: 0,
      marginBottom: "3rem",
    },
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "2rem 0rem",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      width: "50%",
      margin: 0,
    },
  },
  progressDetails: {
    display: "flex",
    width: "100%",
    borderTop: "1px solid #777777",
    alignItems: "center",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      marginTop: 0,
      justifyContent: "space-around",
      gap: "4px",
      borderTop: "none",
    },
  },
  details1: {
    width: "50%",
    height: "165px",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    textAlign: "center",
    borderRight: "1px solid #777",
    justifyContent: "center",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      gap: 0,
      width: "auto",
      borderRight: "none",
      height: "fit-content",
    },
  },
  details2: {
    width: "50%",
    height: "165px",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    textAlign: "center",
    justifyContent: "center",
    "@media (max-width: 800px)": {
      gap: 0,
      width: "auto",
      height: "fit-content",
    },
  },
  responsiveDetails: {
    "@media (max-width: 800px)": {
      display: "flex",
    },
  },
  moduleHeading: {
    padding: "0.8rem 1rem 0rem 1rem",
    marginBottom: "1rem",
    fontWeight: "600",
    "@media (max-width: 800px)": {
      padding: "1rem",
      margin: 0,
    },
  },
  progressHeading: {
    padding: "2.8rem 3rem 0rem 3rem",
    marginBottom: "1rem",
    fontWeight: "600",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      padding: 0,
      marginBottom: "2rem",
    },
  },
  contentMain: {
    fontSize: "19px",
    fontWeight: "800",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      fontSize: "14px",
      fontWeight: "800",
    },
  },
  rateCourseButton: {
    fontSize: "1.5rem",
    fontWeight: "600",
    lineHeight: "1.6rem",
    "&:focus": {
      border: "2px solid black",
      backgroundColor: "black",
      color: "white",
    },
  },
  courseNotFoundSection: {
    width: "400px",
    background: "#1974b5",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: ".8rem",
    "@media (max-width: 500px)": {
      width: "95%",
    },
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Course() {
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const [courseResume, setcourseResume] = useState({});
  const [curObject, setCurObject] = useState({});
  const [curNugget, setCurNugget] = useState({});
  const [remaining, setRemaining] = useState({});
  const [curObjIndex, setCurObjIndex] = useState(0);
  const [curNuggetId, setCurNuggetId] = useState("");
  const [curNugIndex, setCurNugIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  const [tpProgress, setTpProgress] = useState("");
  const [markComplete, setMarkComplete] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [perScore, setPerScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [active, setActive] = useState(false);
  const lastobject = useRef(false);
  const scormpPause = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const variants = ["h4", "h4", "h4", "h4"];
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isBookmarkedLoaded, setIsBookmarkedLoaded] = useState(false);
  const [showScore, setShowScore] = useState(false);
  const [startQuiz, setStartQuiz] = useState(false);
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const coursedestailref = useRef({});
  const history = useHistory();
  let query = useQuery();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isQuizLoading, setIsQuizLoading] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [extUrlType, setExtUrlType] = useState(false);
  const [extUrl, setExtUrl] = useState("");
  const handleOpenFeedback = () => {
    setOpenFeedback(true);
  };
  async function CheckFeedbackSubmitted(course) {
    try {
      const requestBody = {
        tid: course?.tid,
        oid: config.aws_org_id,
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/CheckFeedbackSubmitted?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      setFeedbackSubmitted(response.body);
    } catch (error) {
      console.error(error);
    }
  }
  let src = `${
    config.aws_cloudfront_url
  }${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
    courseDetails.tid
  }.png`;
  useEffect(() => {
    if (!userDetails && config.aws_org_id != "EXCELSOFT") {
      var obj = {};
      obj.tid = query.get("id");
      obj.type = "course";
      localStorage.setItem("deeplink", JSON.stringify(obj));
      if (config.aws_org_id == "nseacademy") {
        window.location.href = `${config.aws_cloudfront_lurl}?id=${query.get(
          "id"
        )}&uid=${query.get("uid")}`;
      } else {
        window.location.href = config.aws_cloudfront_lurl;
      }
    } else if (query.get("course_id") !== undefined) {
      if (
        config.aws_org_id == "EXCELSOFT" &&
        query.get("user_id") !== undefined
      ) {
        getUserVerify();
      } else {
        getTopicDetails();
      }
    } else if (userDetails && query.get("id") && query.get("uid")) {
      getTopicDetails();
    }
    window.scrollTo(0, 0);
    window.nextbtn = undefined;
    return () => {
      if (
        coursedestailref.current.userdataset !== undefined &&
        coursedestailref.current.userdataset.userdata == false
      ) {
        updateCourseAnalytics("bounce");
      }
      const udata = { ...userDetails };
      delete udata.learningPath;
      dispatch(awsSignIn(udata));
    };
  }, [query.get("id"), open, certificate, setCertificate]);
  useEffect(() => {
    // Initialize the SCORM connection
    SCORM.init();
    SCORM.set("cmi.suspend_data", "");
    SCORM.save();
    return () => {
      SCORM.quit();
    };
  }, []);
  const renderyourprogreess = (param) => {
    return (
      <Paper
        elevation={5}
        className={` ${
          courseDetails.prerequisites &&
          courseDetails.prerequisitescourses?.length > 0
            ? classes.paper3
            : matches
            ? classes.paper3
            : classes.paper4
        } ${param.style1}`}
        role="main"
        aria-label="Your progress Section"
      >
        <h3 className={classes?.progressHeading}>{`${userDetails?.languagejson
          ?.Course_View?.Your_progress || "Your progress"}`}</h3>
        <div className={classes?.responsiveDetails}>
          <div elevation={0} className={classes?.progressContainer}>
            {open ? (
              <></>
            ) : (
              <>
                <CircularProgressNugget
                  courseDetails={courseDetails}
                  totalProgress={totalProgress}
                  userDetails={userDetails}
                />
              </>
            )}
          </div>
          {courseDetails.userdataset.userdata === false ||
          tpProgress.charAt(0) == 1 ? (
            <></>
          ) : (
            <>
              <Paper elevation={0} className={classes.btnContainer}></Paper>
            </>
          )}
          <div className={classes.progressDetails}>
            <div className={classes.details1}>
              <p className="centeritem">
                <LayersOutlinedIcon
                  style={{
                    fontSize: "2.8rem",
                    opacity: ".5",
                  }}
                />
              </p>
              <Typography
                component="h5"
                variant="h5"
                style={{ fontFamily: `myfontregular`, fontSize: "1.6rem" }}
              >
                {`${userDetails?.languagejson?.Course_View?.Modules_remaining ||
                  "Modules remaining"}`}
              </Typography>
              <Typography
                component="h4"
                variant="h4"
                className={classes.contentMain}
              >
                {remaining.mremaining}
              </Typography>
            </div>
            <div className={classes.details2}>
              <p className="centeritem">
                <AccessTimeIcon
                  style={{
                    fontSize: "2.8rem",
                    opacity: ".5",
                  }}
                />
              </p>
              <Typography
                component="h5"
                variant="h5"
                style={{ fontFamily: `myfontregular` }}
              >
                {`${userDetails?.languagejson?.Course_View?.Time_remaining ||
                  "Time remaining"}`}
              </Typography>
              <Typography
                component="h4"
                variant="h4"
                className={classes.contentMain}
              >
                {Math.floor(remaining.tremaining / 3600)}h{" "}
                {Math.floor((remaining.tremaining % 3600) / 60)}m{" "}
                {Math.floor((remaining.tremaining % 3600) % 60)}s
              </Typography>
            </div>
          </div>
        </div>
      </Paper>
    );
  };
  async function getUserVerify() {
    try {
      const requestBody = {};
      requestBody.user_id = query.get("user_id");
      requestBody.course_id = query.get("course_id");
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getUserVerified?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      let udtt = { ...userDetails };
      udtt.uData = response.users;
      dispatch(awsSignIn(udtt));
      getTopicDetails(response.users);
    } catch (error) {
      setLoading(false);
    }
  }
  async function getTopicDetails(udtt) {
    try {
      const requestBody = {
        oid: config.aws_org_id,
        eid: userDetails.username,
        tenant: userDetails?.uData?.oid,
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        topicid: query.get("id"),
        urid: userDetails?.uData?.ur_id,
        schema: config.schema,
        learningPath: userDetails?.learningPath,
        groups: userDetails?.uData?.gid,
      };
      if (
        config.aws_org_id == "EXCELSOFT" &&
        query.get("user_id") !== undefined
      ) {
        requestBody.urid = udtt.ur_id;
        requestBody.tenant = udtt.oid;
        requestBody.topicid = query.get("course_id");
      }
      setLoading(true);
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getTopicDetails?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      setLoading(true);
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );

      const firstNugget = response.nuggets[0];
      if (
        firstNugget &&
        firstNugget.objects &&
        firstNugget.objects.length > 0
      ) {
        const firstObject = firstNugget.objects[0];
        setExtUrlType(firstObject.ext_url_type); // Update ext_url_type state
        setExtUrl(firstObject.ext_url); // Update ext_url state
      }

      coursedestailref.current.userdataset = response.userdataset;
      coursedestailref.current.tid = response.tid;
      setCourseDetails(response);
      if (
        response.userdataset !== undefined &&
        response.userdataset.userprogress !== undefined &&
        response.userdataset.userprogress.bookmark === true
      ) {
        setIsBookmarked(true);
      } else {
        setIsBookmarked(false);
      }
      setcourseResume(response.nresume);
      setRemaining({
        mremaining: response.mremaining,
        tremaining: response.tremaining,
      });
      if (response.userdataset.userdata == true) {
        setTotalProgress(
          response.userdataset.cobj === undefined
            ? 0
            : response.userdataset.cobj
        );
        setTpProgress(response.userdataset.tp);
      }
      CheckFeedbackSubmitted(response);
      updateRecentViewed(response);
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", response.Policy);
      Cookies.set("CloudFront-Signature", response.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
      setLoading(false);
      setIsLoading(false);
    } catch (err) {
      setLoading(false);
      setIsLoading(false);
      console.error(err);
    }
  }
  async function addAndRemoveBookmark(val) {
    try {
      const bdata1 = {
        oid: config.aws_org_id,
        tid: courseDetails.tid,
        type: "topics",
        eid: userDetails.username,
        userfullname: userDetails.name,
        emailid: userDetails?.email,
        tenant: userDetails?.locale,
        ur_id: userDetails?.uData?.ur_id,
        bookmark: val === 1 ? true : false,
        schema: config.schema,
      };
      if (val === 1) {
        bdata1.bookmark_date = 1;
      }
      const jsonString = JSON.stringify(bdata1);
      const base64EncodedString = btoa(jsonString);
      const bodyParam = {
        body: {
          json: base64EncodedString,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      };
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );
      val === 1 ? setIsBookmarked(true) : setIsBookmarked(false);
      setIsBookmarkedLoaded(false);
    } catch (error) {
      console.error(error);
    }
  }
  async function getQuizScore(objects) {
    try {
      setLoading(true);
      setIsQuizLoading(true);
      const requestBody = {
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        eid: userDetails.username,
        emailid: userDetails.email,
        obj_id: objects.oid,
        ur_id: userDetails.uData.ur_id,
        tid: courseDetails.tid,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getQuizScore?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      setShowScore(true);
      setLoading(false);
      setIsQuizLoading(false);
      setStartQuiz(true);
      if (response && response.length > 0) {
        setPerScore(response[response?.length - 1]);
        setTotalScore(response[response?.length - 1]);
      }
    } catch (error) {
      console.error(error);
      setLoading(true);
      setIsQuizLoading(false);
    }
  }
  async function updateRecentViewed(cDetails) {
    try {
      const bdata = {
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        eid: userDetails.username,
        tid: cDetails.tid,
        title: cDetails.ttitle,
        type: "topic",
        pdate: new Date().getTime(),
        tduration: cDetails.tduration,
        noofnuggets: cDetails.noofnuggets,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(bdata);
      const base64EncodedString = btoa(jsonString);
      const bodyParam = {
        body: {
          json: base64EncodedString,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      };
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateRecentViewed",
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }
  async function updateCourseAnalytics(ctype) {
    const data2 = {
      oid: config.aws_org_id,
      tid: courseDetails.tid,
      email: userDetails.username,
      tenant: userDetails.locale,
      ctype,
      ur_id: userDetails.uData.ur_id,
      schema: config.schema,
    };
    if (ctype == "bounce") {
      data2.bounce_cnt = 1;
    }
    if (ctype == "completed" || ctype == 2) {
      data2.comp_date = 1;
    }
    const qinfo = await new Promise((resolve) => {
      const qinfoTemp = [];
      courseDetails?.nuggets?.forEach((nugget) => {
        if (Array.isArray(nugget.objects)) {
          nugget.objects.forEach((item) => {
            let qscore = [];
            if (item.otype === "quiz") {
              const qname = item.otitle;
              const qlen = item.quiz_length;
              const qper = parseFloat(
                (
                  (parseInt(item?.score[item?.score?.length - 1]) /
                    item?.quiz_length) *
                  100
                ).toFixed(2)
              );
              qscore = parseInt(item?.score[item?.score?.length - 1]);
              const qreport =
                parseFloat(
                  (
                    (parseInt(item?.score[item?.score?.length - 1]) /
                      item?.quiz_length) *
                    100
                  ).toFixed(2)
                ) >= item?.cutoff
                  ? "pass"
                  : "fail";
              qinfoTemp.push({ qname, qscore, qreport, qlen, qper });
            }
          });
        }
      });
      resolve(qinfoTemp);
    });
    if (qinfo.length > 0) {
      data2.qinfo = qinfo;
    }
    const jsonString = JSON.stringify(data2);
    const base64EncodedString = btoa(jsonString);
    const bodyParam1 = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam1
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  const handleModalOpen = async (nresume) => {
    if (navigator.onLine === true) {
      for (let k = 0; k < courseDetails.nuggets.length; k++) {
        let nflg = 0;
        for (let l = 0; l < courseDetails.nuggets[k].objects.length; l++) {
          if (
            courseDetails.nuggets[k].objects[l].op === 1 ||
            courseDetails.nuggets[k].objects[l].op === "1"
          ) {
            if (courseDetails.nuggets[k].objects[l].otype == "scorm") {
              scormpPause.current = false;
              var obj2 = {};
              obj2.ur_id = userDetails.uData?.ur_id;
              obj2.cid = courseDetails.tid;
              obj2.tid = courseDetails.tid;
              obj2.objid = courseDetails.nuggets[k].objects[l].oid;
              obj2.atype = 1;
              obj2.schema = config.schema;
              obj2.curObjIndex = curObjIndex;
              obj2.objlength = courseDetails.nuggets[k].objects.length - 1;
              obj2.curNugIndex = curNugIndex;
              obj2.nuggetlength = courseDetails.nuggets.length - 1;
              var tobj = JSON.stringify(obj2);
              var val = await GetApiCall(tobj, obj2);
              if (val) {
                setCurNugget(courseDetails.nuggets[k]);
                setCurObject(courseDetails.nuggets[k].objects[l]);
                setCurObjIndex(l);
                setCurNuggetId(courseDetails.nuggets[k].nid);
                setCurNugIndex(k);
                setMarkComplete(false);
                setStartTime(new Date().getTime());
                setProgress(
                  courseDetails.nuggets[k].objects[l].rtime ??
                    courseDetails.nuggets[k].objects[l].oduration
                );
                setOpen(true);
                setIsLoading(false);
                setLoading(false);
              }
            } else {
              setCurNugget(courseDetails.nuggets[k]);
              setCurObject(courseDetails.nuggets[k].objects[l]);
              setCurObjIndex(l);
              setCurNuggetId(courseDetails.nuggets[k].nid);
              setCurNugIndex(k);
              setMarkComplete(false);
              setStartTime(new Date().getTime());
              setProgress(
                courseDetails.nuggets[k].objects[l].rtime ??
                  courseDetails.nuggets[k].objects[l].oduration
              );
              setOpen(true);
              setIsLoading(false);
              setLoading(false);
            }
            nflg = 1;
            break;
          }
        }
        if (nflg === 1) {
          break;
        }
      }
    }
    setStartQuiz(false);
    setShowScore(false);
  };
  function ProgramView(pid) {
    if (userDetails.location.pFrom !== undefined) {
      return `/ExploreCourseView?from=0&id=${pid}`;
    } else {
      return `/ExploreCourseView?id=${pid}`;
    }
  }
  async function GetApiCall(json, obj2) {
    try {
      const requestBody = json;
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getScormData?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      const data = response;
      window.nextbtn = "get";
      if (data?.body?.stime !== undefined && data?.body?.stime !== null) {
        obj2.stime = data.body.stime;
      }
      if (
        data?.body?.suspend_data !== undefined &&
        data?.body?.suspend_data !== null
      ) {
        obj2.susdt = data.body.suspend_data;
        window.cudetails = obj2;
        window.API.LMSSetValue("cmi.suspend_data", data.body.suspend_data);
        window.API_1484_11.SetValue("cmi.suspend_data", data.body.suspend_data);
        window.API_1484_11.Commit("");
        SCORM.set("cmi.suspend_data", data.body.suspend_data);
        SCORM.save();
      } else {
        obj2.susdt = "";
        window.API.LMSSetValue("cmi.suspend_data", "");
        SCORM.set("cmi.suspend_data", "");
        SCORM.save();
        window.cudetails = obj2;
      }
      if (data?.body?.op === 2) {
        window.cudetails.op = 2;
      }
      return true;
    } catch (error) {
      window.nextbtn = "get";
      console.error("LMSSetValue Error:", error);
      return true;
    }
  }
  return (
    <div>
      <SessionTimeOut active={open ? true : false} setActive={setActive} />
      {courseDetails.id !== "1" && courseDetails.tstatus !== false ? (
        <>
          {userDetails !== 0 &&
            userDetails !== undefined &&
            userDetails.location !== undefined && (
              <>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      style={{ width: "20%" }}
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  userDetails.location.from !== undefined &&
                  (userDetails.location.from === "Home" ||
                    userDetails.location.from === "Bookmarks" ||
                    userDetails.location.from === "View All Courses" ||
                    userDetails.location.from === "Explore" ||
                    userDetails.location.from === "Search") && (
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          fontSize="large"
                          style={{ fill: "var(--color-primary)" }}
                        />
                      }
                      aria-label="breadcrumb"
                      style={{ marginBottom: "2rem", cursor: "pointer" }}
                    >
                      {userDetails.location.from == "Home" && (
                        <Link
                          color="inherit"
                          to={
                            userDetails.location.from === "Home" ? "/home" : "#"
                          }
                          style={{
                            color: `${
                              userDetails.location.from === "Home"
                                ? "#1974b5"
                                : "#777"
                            }`,
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: `${
                              userDetails.location.from === "Home"
                                ? "pointer"
                                : "context-menu"
                            }`,
                          }}
                        >
                          {`${userDetails?.languagejson?.breadcrumbs?.Home ||
                            userDetails.location.from}`}
                        </Link>
                      )}
                      {userDetails.location.lname &&
                        userDetails.location.from === "Home" &&
                        (userDetails?.courseName === "" ||
                          userDetails?.courseName ===
                            courseDetails?.ttitle) && (
                          <Link
                            to={userDetails.location.lname ? `/learning` : "#"}
                            style={{
                              color: `${
                                userDetails.location.lname ? "#1974b5" : "#777"
                              }`,
                              fontSize: "1.5rem",
                              textDecoration: "none",
                              cursor: `${
                                userDetails.location.lname
                                  ? "pointer"
                                  : "context-menu"
                              }`,
                            }}
                            color="inherit"
                          >
                            {userDetails.location.lname}
                          </Link>
                        )}
                      {userDetails.location.from == "View All Courses" ? (
                        <Link
                          color="inherit"
                          to={
                            userDetails.location.from === "View All Courses"
                              ? "/viewallcourses"
                              : "#"
                          }
                          style={{
                            color: `${
                              userDetails.location.from === "View All Courses"
                                ? "#1974b5"
                                : "#777"
                            }`,
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: `${
                              userDetails.location.from === "View All Courses"
                                ? "pointer"
                                : "context-menu"
                            }`,
                          }}
                        >
                          {`${userDetails?.languagejson?.breadcrumbs
                            ?.View_All_Courses || userDetails.location.from}`}
                        </Link>
                      ) : (
                        ""
                      )}
                      {userDetails.location.from == "Explore" && (
                        <Link
                          to={
                            userDetails.location.from === "Explore"
                              ? "/exploreTopics"
                              : "#"
                          }
                          style={{
                            color: `${
                              userDetails.location.from === "Explore"
                                ? "#1974b5"
                                : "#777"
                            }`,
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: `${
                              userDetails.location.from === "Explore"
                                ? "pointer"
                                : "context-menu"
                            }`,
                          }}
                          color="inherit"
                        >
                          {`${userDetails?.languagejson?.breadcrumbs?.Explore ||
                            userDetails.location.from}`}
                        </Link>
                      )}
                      {userDetails.location.from == "Search" && (
                        <Link
                          to={
                            userDetails.location.from === "Search"
                              ? "/home"
                              : "#"
                          }
                          style={{
                            color: "#777",
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: "context-menu",
                          }}
                          color="inherit"
                        >
                          {`${userDetails?.languagejson?.breadcrumbs?.Search ||
                            userDetails.location.from}`}
                        </Link>
                      )}
                      {userDetails.location.from == "Bookmarks" && (
                        <Link
                          color="inherit"
                          to={
                            userDetails.location.from === "Bookmarks"
                              ? "/bookmarks"
                              : "#"
                          }
                          style={{
                            color: `${
                              userDetails.location.from === "Bookmarks"
                                ? "#1974b5"
                                : "#777"
                            }`,
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: `${
                              userDetails.location.from === "Bookmarks"
                                ? "pointer"
                                : "context-menu"
                            }`,
                          }}
                        >
                          {`${userDetails?.languagejson?.breadcrumbs
                            ?.Bookmarks || userDetails.location.from}`}
                        </Link>
                      )}
                      {userDetails.location.topicName &&
                        userDetails.location.topicId ===
                          courseDetails?.cgry_id && (
                          <Link
                            to={
                              userDetails.location.courseName
                                ? `/explore/${userDetails.location.topicName.replaceAll(
                                    /\s/g,
                                    "-"
                                  )}/${userDetails.location.topicId}`
                                : "#"
                            }
                            style={{
                              color: `${
                                userDetails.location.courseName
                                  ? "#1974b5"
                                  : "#777"
                              }`,
                              fontSize: "1.5rem",
                              textDecoration: "none",
                              cursor: `${
                                userDetails.location.courseName
                                  ? "pointer"
                                  : "context-menu"
                              }`,
                            }}
                            color="inherit"
                          >
                            {userDetails.location.topicName}
                          </Link>
                        )}
                      {userDetails.location.lname &&
                        userDetails.location.from === "Explore" &&
                        (userDetails?.courseName === "" ||
                          userDetails?.courseName === courseDetails.ttitle) && (
                          <Link
                            to={userDetails.location.lname ? `/learning` : "#"}
                            style={{
                              color: `${
                                userDetails.location.lname ? "#1974b5" : "#777"
                              }`,
                              fontSize: "1.5rem",
                              textDecoration: "none",
                              cursor: `${
                                userDetails.location.lname
                                  ? "pointer"
                                  : "context-menu"
                              }`,
                            }}
                            color="inherit"
                          >
                            {userDetails.location.lname}
                          </Link>
                        )}
                      {userDetails.location.viewAll && (
                        <Link
                          to={
                            userDetails.location.viewAllRoute
                              ? userDetails.location.viewAllRoute
                              : "/ExploreViewAll"
                          }
                          color="inherit"
                          style={{
                            color: "#1974b5",
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        >
                          {`${userDetails?.languagejson?.Home_Page?.View_all ||
                            "View all"}`}
                        </Link>
                      )}
                      {userDetails.location.programName && (
                        <Link
                          to={ProgramView(userDetails.location.programId)}
                          style={{
                            color: "#1974b5",
                            fontSize: "1.5rem",
                            textDecoration: "none",
                          }}
                          color="inherit"
                        >
                          {userDetails.location.programName}
                        </Link>
                      )}
                      <Link
                        style={{
                          color: "#777",
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: "context-menu",
                        }}
                        color="inherit"
                      >
                        {courseDetails.ttitle}
                      </Link>
                    </Breadcrumbs>
                  )
                )}
              </>
            )}
          <div className="flex-between">
            <div className="flex-container flex-container-responsive">
              {isLoading ? (
                <>
                  <Typography
                    component="h1"
                    variant="h3"
                    className="loadingcss"
                  >
                    <Skeleton animation="wave" />
                  </Typography>
                </>
              ) : (
                <>
                  <h3 style={{ fontWeight: "600" }}>{courseDetails.ttitle}</h3>
                </>
              )}
              <div className="responsive">
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ minWidth: "80px", margin: "5px" }}
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <>
                    <span
                      className="mx responsiveNone"
                      style={{
                        color: "#555555",
                        borderRight: "2px solid #555555",
                        height: "25px",
                      }}
                    ></span>
                    {config.ratingFeature && (
                      <StarRoundedIcon
                        className="icon icon-star"
                        style={{ marginRight: ".5rem" }}
                      />
                    )}
                    {config.ratingFeature && (
                      <span className={classes.iconResponsiveRating}>
                        {courseDetails?.star?.toFixed(1) || "0.0"}
                      </span>
                    )}
                  </>
                )}
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ minWidth: "80px", margin: "5px" }}
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <>
                    <div
                      className={"bgimage modules "}
                      style={{ height: "30px", width: "25px" }}
                    ></div>
                    <span className={classes.textResponsive}>
                      {courseDetails.noofnuggets}{" "}
                      {`${userDetails?.languagejson?.Course_View?.Modules ||
                        "Modules"}`}
                    </span>
                  </>
                )}
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ minWidth: "80px", margin: "5px" }}
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <>
                    <AccessTimeIcon className={classes.iconResponsive} />
                    <span className={classes.textResponsive}>
                      {Math.floor(courseDetails.tduration / 3600)}h{" "}
                      {Math.floor((courseDetails.tduration % 3600) / 60)}m{" "}
                      {Math.floor((courseDetails.tduration % 3600) % 60)}s
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="flex-container" style={{ gap: "2rem" }}>
              {isLoading ? (
                <></>
              ) : (
                <>
                  {courseDetails?.userdataset?.userdata == true &&
                  tpProgress !== undefined &&
                  !open &&
                  config.ratingFeature &&
                  courseDetails?.userdataset?.userprogress !== undefined &&
                  (courseDetails?.userdataset?.userprogress.rating ===
                    undefined ||
                    courseDetails?.userdataset?.userprogress?.rating ===
                      null) &&
                  (tpProgress.charAt(0) == 2 || tpProgress.charAt(0) == 3) ? (
                    <>
                      <RatingPopUp courseDetails={courseDetails} />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {isLoading ? (
                <>
                  {" "}
                  <Typography
                    component="h1"
                    variant="h5"
                    style={{ minWidth: "80px", margin: "5px" }}
                  >
                    <Skeleton animation="wave" />
                  </Typography>
                </>
              ) : (
                courseDetails?.userdataset?.userdata == true &&
                tpProgress !== undefined &&
                courseDetails?.userdataset?.userprogress !== undefined &&
                (tpProgress.charAt(0) == 2 || tpProgress.charAt(0) == 3) &&
                (courseDetails?.feedback === "true" ||
                  courseDetails?.feedback === true) &&
                !feedbackSubmitted && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.rateCourseButton}
                      onClick={handleOpenFeedback}
                    >
                      {" "}
                      {`${userDetails?.languagejson?.Course_View
                        ?.FEEDBACK_FORM || "Feedback form"}`}
                    </Button>
                    <FeedbackPopUp
                      courseDetails={courseDetails}
                      handleOpenFeedback={handleOpenFeedback}
                      openFeedback={openFeedback}
                      setOpenFeedback={setOpenFeedback}
                      CheckFeedbackSubmitted={CheckFeedbackSubmitted}
                    />
                  </>
                )
              )}
              {isLoading ? (
                <>
                  <Typography
                    component="h1"
                    variant="h5"
                    style={{ minWidth: "80px", margin: "5px" }}
                  >
                    <Skeleton animation="wave" />
                  </Typography>
                </>
              ) : (
                <>
                  {isBookmarked ? (
                    <button
                      className={classes.bookmarkIconResponsive}
                      title="Bookmarked"
                      onClick={() => {
                        setIsBookmarkedLoaded(true);
                        addAndRemoveBookmark(0);
                      }}
                    >
                      <BookmarkRoundedIcon className={classes.filled} />
                    </button>
                  ) : (
                    <button
                      className={classes.bookmarkIconResponsive}
                      title="Bookmark"
                      onClick={() => {
                        setIsBookmarkedLoaded(true);
                        addAndRemoveBookmark(1);
                      }}
                    >
                      <BookmarkRoundedIcon className={classes.outline} />
                    </button>
                  )}{" "}
                </>
              )}
              <Backdrop className={classes.backdrop} open={isBookmarkedLoaded}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>
          <div className={classes.root}>
            <GridWrapper
              layout={
                courseDetails.prerequisites &&
                courseDetails.prerequisitescourses?.length > 0
                  ? true
                  : false
              }
            >
              <Paper
                elevation={5}
                className={`${classes.paper} grid-item1`}
                role="main"
                aria-label="Overview Section"
              >
                <div>
                  <h3 style={{ fontWeight: "600", marginBottom: "1rem" }}>
                    {`${userDetails?.languagejson?.Course_View?.Overview ||
                      "Overview"}`}
                  </h3>
                  {isLoading ? (
                    <>
                      {variants.map((variant) => (
                        <Typography
                          component="h1"
                          variant={variant}
                          className="loadingcss"
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      ))}
                    </>
                  ) : (
                    <>
                      <div
                        elevation={0}
                        className={`${classes.body} responsiveOverview`}
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="body1"
                          className={classes.textSection}
                          gutterBottom
                        >
                          {courseDetails.tdescription}
                        </Typography>
                        <img src={src} className="responsiveImage" alt="" />
                      </div>
                    </>
                  )}
                </div>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h1"
                      style={{ margin: "6px auto" }}
                    >
                      <Skeleton animation="wave" width={150} height={60} />
                    </Typography>
                  </>
                ) : (
                  <>
                    {courseDetails?.userdataset?.userdata && open && (
                      <div
                        style={{ marginTop: "30px" }}
                        elevation={0}
                        className={classes.btnContainer}
                      >
                        <ObjectPopUp
                          open={open}
                          setOpen={setOpen}
                          setCourseDetails={setCourseDetails}
                          courseDetails={courseDetails}
                          setCurObject={setCurObject}
                          curObject={curObject}
                          setCurNugget={setCurNugget}
                          curNugget={curNugget}
                          setCurObjIndex={setCurObjIndex}
                          curObjIndex={curObjIndex}
                          setCurNuggetId={setCurNuggetId}
                          curNuggetId={curNuggetId}
                          progress={progress}
                          setProgress={setProgress}
                          curNugIndex={curNugIndex}
                          setCurNugIndex={setCurNugIndex}
                          markComplete={markComplete}
                          setMarkComplete={setMarkComplete}
                          setStartTime={setStartTime}
                          startTime={startTime}
                          courseResume={courseResume}
                          setcourseResume={setcourseResume}
                          remaining={remaining}
                          setRemaining={setRemaining}
                          setTotalProgress={setTotalProgress}
                          totalProgress={totalProgress}
                          tpProgress={tpProgress}
                          setTpProgress={setTpProgress}
                          perScore={perScore}
                          setPerScore={setPerScore}
                          totalScore={totalScore}
                          setTotalScore={setTotalScore}
                          showScore={showScore}
                          setShowScore={setShowScore}
                          startQuiz={startQuiz}
                          setStartQuiz={setStartQuiz}
                          loading={loading}
                          setLoading={setLoading}
                          certificate={certificate}
                          setCertificate={setCertificate}
                          isLoading={isQuizLoading}
                          setIsLoading={setIsQuizLoading}
                          updateCourseAnalytics={updateCourseAnalytics}
                          scormpPause={scormpPause}
                          getTopicDetails={getTopicDetails}
                          lastobject={lastobject}
                          extUrlType={extUrlType}
                          setExtUrlType={setExtUrlType}
                          extUrl={extUrl}
                          setExtUrl={setExtUrl}
                        />
                      </div>
                    )}
                    <div
                      style={{ marginTop: "30px" }}
                      elevation={0}
                      className={classes.btnContainer}
                    >
                      {tpProgress.charAt(0) == 1 &&
                        !courseDetails.freenavigation &&
                        !open &&
                        courseDetails?.userdataset?.userdata && (
                          <>
                            {!courseDetails.freenavigation && (
                              <StyledButton
                                onClick={() => handleModalOpen(courseResume)}
                                disabled={loading}
                              >
                                Resume
                              </StyledButton>
                            )}
                          </>
                        )}
                    </div>
                    {!courseDetails?.userdataset?.userdata && (
                      <div elevation={0} className={classes.btnContainer}>
                        <TransitionsModal
                          open={open}
                          setOpen={setOpen}
                          setCourseDetails={setCourseDetails}
                          setCurNugget={setCurNugget}
                          setCurObject={setCurObject}
                          setCurObjIndex={setCurObjIndex}
                          setCurNuggetId={setCurNuggetId}
                          setCurNugIndex={setCurNugIndex}
                          setCertificate={setCertificate}
                          setProgress={setProgress}
                          setMarkComplete={setMarkComplete}
                          setStartTime={setStartTime}
                          courseDetails={courseDetails}
                          setTpProgress={setTpProgress}
                          setcourseResume={setcourseResume}
                          updateCourseAnalytics={updateCourseAnalytics}
                          scormpPause={scormpPause}
                          getTopicDetails={getTopicDetails}
                          curNugIndex={curNugIndex}
                          curObjIndex={curObjIndex}
                        />
                      </div>
                    )}
                  </>
                )}
              </Paper>
              <Paper
                elevation={5}
                className={`${
                  config?.course?.progress_view
                    ? classes.paper
                    : classes.paperModules
                } grid-item2`}
                role="main"
                aria-label="Modules Section"
              >
                <h3 className={classes.moduleHeading}>{`${userDetails
                  ?.languagejson?.Course_View?.Modules || "Modules"}`}</h3>
                {isLoading ? (
                  <>
                    <NuggetsLoader type="nuggetobject"></NuggetsLoader>
                    <NuggetsLoader type="nuggetobject"></NuggetsLoader>
                    <NuggetsLoader type="nuggetobject"></NuggetsLoader>
                  </>
                ) : (
                  <>
                    {courseDetails.nuggets?.map((nuggets, nindex) => (
                      <>
                        <div
                          style={{
                            marginTop: "2rem",
                            marginBottom: "1rem",
                            fontFamily: `myfontregular`,
                          }}
                        >
                          <div className={classes.flexHeader}>
                            <Typography
                              component="h4"
                              variant="h5"
                              className="modulesSubHead modulesSubHeadResponsive"
                              style={{
                                fontFamily: `myfontregular`,
                                width: "fit-content",
                              }}
                            >
                              {nuggets.ntitle}
                            </Typography>
                            <Typography className={classes.timeHeader}>
                              <AccessTimeIcon
                                style={{
                                  fontSize: "21px",
                                  marginRight: "8px",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: "15px",
                                  width: "max-content",
                                  fontWeight: "600",
                                }}
                              >
                                {Math.floor(nuggets.nduration / 3600)}h{" "}
                                {Math.floor((nuggets.nduration % 3600) / 60)}m{" "}
                                {Math.floor((nuggets.nduration % 3600) % 60)}s
                              </span>
                            </Typography>
                          </div>
                        </div>
                        {nuggets.objects?.map((objects, index) => (
                          <div className="objflex-between">
                            <Typography
                              component="h3"
                              variant="h5"
                              className="modulesObjecticon"
                            >
                              <Renderimage
                                type={objects.otype.toLowerCase()}
                                ostatus={objects.op}
                              ></Renderimage>
                            </Typography>
                            <Typography
                              component="h3"
                              variant="h5"
                              className="modulesObject"
                            >
                              {objects.op === 0 ? (
                                <>{objects.otitle}</>
                              ) : (
                                <>
                                  <Link
                                    component="button"
                                    color="inherit"
                                    style={{
                                      fontSize: "1.6rem",
                                      textAlign: "left",
                                      color: "#1C6D9C",
                                      background: "transparent",
                                      border: "none",
                                      cursor: "pointer",
                                      fontWeight: "600",
                                      fontFamily: `myfontregular`,
                                    }}
                                    variant="body2"
                                    onClick={async () => {
                                      let obj = {};
                                      obj.curNugget = nuggets;
                                      obj.curObject = objects;
                                      obj.robject = index;
                                      setCurNugget(nuggets);
                                      setCurObject(objects);
                                      setCurObjIndex(index);
                                      setCurNuggetId(nuggets.nid);
                                      setCurNugIndex(nindex);
                                      setLoading(false);
                                      setIsQuizLoading(false);
                                      setCertificate(false);
                                      setStartQuiz(false);
                                      setShowScore(false);
                                      if (
                                        objects.qtype == 1 &&
                                        objects.op == 2
                                      ) {
                                        setLoading(true);
                                        setLoading(false);
                                        setIsQuizLoading(false);
                                        getQuizScore(objects);
                                      }
                                      if (
                                        objects.op == 1 &&
                                        courseDetails.userdataset !==
                                          undefined &&
                                        courseDetails.userdataset.tresume !==
                                          undefined &&
                                        courseDetails.userdataset.tresume.oid ==
                                          objects.oid
                                      ) {
                                        setProgress(
                                          courseDetails.userdataset.tresume
                                            .obtime
                                        );
                                      } else {
                                        setProgress(
                                          Number(
                                            nuggets.objects[index].oduration
                                          )
                                        );
                                      }
                                      var obj2 = {};
                                      obj2.ur_id = userDetails.uData?.ur_id;
                                      obj2.cid = query.get("id");
                                      obj2.tid = query.get("id");
                                      obj2.objid = objects.oid;
                                      obj2.atype = 0;
                                      obj2.schema = config.schema;
                                      obj2.curObjIndex = index;
                                      obj2.objlength =
                                        nuggets.objects.length - 1;
                                      obj2.curNugIndex = nindex;
                                      obj2.nuggetlength =
                                        courseDetails.nuggets.length - 1;
                                      if (
                                        objects.otype === "scorm" &&
                                        objects.op == 1
                                      ) {
                                        scormpPause.current = false;
                                        var tobj = JSON.stringify(obj2);
                                        var val = await GetApiCall(tobj, obj2);
                                        if (val) {
                                          setMarkComplete(false);
                                          setStartTime(new Date().getTime());
                                          setOpen(true);
                                        }
                                      } else {
                                        setMarkComplete(false);
                                        setStartTime(new Date().getTime());
                                        setOpen(true);
                                      }
                                      setShowScore(false);
                                    }}
                                  >
                                    {objects.otitle}
                                  </Link>
                                </>
                              )}
                            </Typography>
                          </div>
                        ))}
                      </>
                    ))}
                  </>
                )}
              </Paper>
              {isLoading ? (
                config?.course?.progress_view && (
                  <>
                    <Paper
                      elevation={5}
                      className={`${classes.paper} grid-item3`}
                    >
                      <h3 style={{ marginBottom: "2.4rem", fontWeight: "600" }}>
                        {`${userDetails?.languagejson?.Course_View
                          ?.Prerequisites || "Prerequisites"}`}
                      </h3>
                      <Typography
                        component="h1"
                        variant="h5"
                        style={{ minWidth: "150px" }}
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </Paper>
                  </>
                )
              ) : (
                <>
                  {courseDetails.prerequisites &&
                    config?.course?.progress_view &&
                    courseDetails.prerequisitescourses?.length > 0 && (
                      <Paper
                        elevation={5}
                        className={`${classes.paper} grid-item3`}
                        onClick={() => {
                          let breadcrumb = { ...userDetails };
                          breadcrumb.location = {
                            from: "Home",
                            courseName:
                              courseDetails.prerequisitescourses[0]?.ttitle,
                          };
                          dispatch(awsSignIn(breadcrumb));
                          history.push(
                            `/course?id=${courseDetails.prerequisitescourses[0]?.tid}`
                          );
                        }}
                      >
                        <h3
                          variant="h4"
                          style={{
                            marginBottom: "2.4rem",
                            fontWeight: "600",
                          }}
                        >
                          {`${userDetails?.languagejson?.Course_View
                            ?.Prerequisites || "Prerequisites"}`}
                        </h3>
                        <CourseCard
                          title={courseDetails.prerequisitescourses[0]?.ttitle}
                          tid={courseDetails.prerequisitescourses[0]?.tid}
                          courseId={query.get("id")}
                        />
                      </Paper>
                    )}
                </>
              )}
              {isLoading ? (
                config?.course?.progress_view && (
                  <>
                    <Paper
                      elevation={5}
                      className={`${classes.paper} grid-item4`}
                      role="main"
                      aria-label="Your progress Section"
                    >
                      <h3 className={classes.progressHeading}>{`${userDetails
                        ?.languagejson?.Course_View?.Your_progress ||
                        "Your progress"}`}</h3>
                      <Paper
                        elevation={0}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "2rem 0rem",
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="h1"
                          style={{ fontFamily: `myfontregular` }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="circle"
                            width={140}
                            height={140}
                          />
                        </Typography>
                      </Paper>
                      <div className={classes.progressDetails}>
                        <div className={classes.details1}>
                          <Typography
                            component="h5"
                            variant="h5"
                            style={{ fontFamily: `myfontregular` }}
                          >
                            {`${userDetails?.languagejson?.Course_View
                              ?.Modules_remaining || "Modules remaining"}`}
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h5"
                            style={{
                              minWidth: "10px",
                              padding: "0 80px",
                              fontFamily: `myfontregular`,
                            }}
                          >
                            <Skeleton animation="wave" />
                          </Typography>
                        </div>
                        <div className={classes.details2}>
                          <Typography component="h5" variant="h5">
                            {`${userDetails?.languagejson?.Course_View
                              ?.Time_remaining || "Time remaining"}`}
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h5"
                            style={{
                              minWidth: "10px",
                              padding: "0 80px",
                              fontFamily: `myfontregular`,
                            }}
                          >
                            <Skeleton animation="wave" />
                          </Typography>
                        </div>
                      </div>
                    </Paper>
                  </>
                )
              ) : (
                <>
                  {courseDetails.prerequisites === true ? (
                    <>
                      {renderyourprogreess(
                        courseDetails,
                        (courseDetails.style1 = "grid-item4")
                      )}
                    </>
                  ) : (
                    config?.course?.progress_view && (
                      <>
                        {" "}
                        {renderyourprogreess(
                          courseDetails,
                          (courseDetails.style1 = "grid-item5")
                        )}{" "}
                      </>
                    )
                  )}
                </>
              )}
            </GridWrapper>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "90vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          {config.aws_org_id != "EXCELSOFT" ? (
            <div className={classes.courseNotFoundSection}>
              <div style={{ margin: "3rem 1rem 0rem", textAlign: "center" }}>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "1.75rem",
                    fontWeight: "600",
                  }}
                >
                  Course you are looking for could not be found
                </p>
                <button
                  style={{
                    border: "none",
                    color: "#1974b5",
                    background: "#fff",
                    padding: ".5rem 2rem",
                    margin: "3rem 1rem",
                    borderRadius: ".4rem",
                    fontSize: "1.45rem",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/home")}
                >
                  Go to dashboard
                </button>
              </div>
              <div
                style={{
                  margin: "0rem 1rem 3rem",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <span style={{ marginRight: ".5rem" }}> Please contact</span>
                <a href="https://www.help@excelearn.com">help@excelearn.com</a>
                <span style={{ marginLeft: ".2rem" }}> for assistance</span>
              </div>
            </div>
          ) : (
            <>Contact Admin</>
          )}
        </div>
      )}
    </div>
  );
}
export default Course;
