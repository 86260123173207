import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { breakpoints, breakpoints3 } from "./utils/Breakpoints";
import "swiper/components/navigation/navigation.scss";
import noThingIsHere from "../../assets/nothing-here-icon.png";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper";
import CardLarge from "../Cards/CardLarge/CardLarge";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import { setViewAllArrData } from "../../redux/HomeData/HomeDataSlice";
import "./Carousel.scss";
import ContentCard from "../Cards/ContentCard/ContentCard";
import Loading from "../../components/Loading/Loading";
import config from "../../aws-exports";
import CardPrimary from "../Cards/Card.Primary";
import { session } from "../../redux/Session/Session";
import moment from "moment";
import defaultIm from "../../assets/defaultItemImage.jpg";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual]);
const ref_types = {
  course: 1,
  content: 2,
  session: 3,
};
const Carousel = ({ heading, cardData, loading, ...rest }) => {
  const history = useHistory();
  const userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const sliderRef = React.useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);
  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  const handleLocation = (tid, otype, title, objid, oname) => {
    let breadcrumb = { ...userDetails };
    if (!otype) {
      breadcrumb.location = {
        from: "Home",
        courseName: title,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`course?id=${tid}`);
    } else {
      breadcrumb.location = {
        from: "Home",
        contentName: title || oname,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`content?id=${objid || tid}`);
    }
  };
  function handleViewAll() {
    dispatch(setViewAllArrData(cardData));
  }
  async function addAndRemoveBookmark(val, id, idx) {
    try {
      const bodyParam = {
        oid: config.aws_org_id,
        tid: id,
        type: "topics",
        eid: userDetails.username,
        userfullname: userDetails.name,
        emailid: userDetails?.email,
        tenant: userDetails?.locale,
        ur_id: userDetails?.uData?.ur_id,
        bookmark: val === 1 ? true : false,
        schema: config.schema,
      };
      if (val === 1) {
        bodyParam.bookmark_date = 1;
      }
      const requestBody = bodyParam;
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = "/updateTopicReport";
      await API.post(config.aws_cloud_logic_custom_name, reportInfo, {
        body: {
          json: base64EncodedString,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      });
      let updateBookmark = [...cardData];
      updateBookmark[idx].bk = val === 1 ? true : false;
      rest.setBookm(!rest.bm);
      rest.setData(updateBookmark);
    } catch (error) {
      console.error(error);
    }
  }
  const addAndRemoveBookmarkContent = async (val, id, idx) => {
    const bodydata = {
      objid: id,
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
    };
    if (val === 0) {
      bodydata.bookmark = true;
      bodydata.bookmark_date = 1;
    } else {
      bodydata.bookmark = false;
    }
    const jsonString = JSON.stringify(bodydata);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
      let updateBookmark = [...cardData];
      updateBookmark[idx].bk = val === 0 ? true : false;
      rest.setData(updateBookmark);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="carousel-container" role="main" aria-label="Course Section">
      <div className="heading-container">
        <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
          <h1 className="carousel-heading">{heading}</h1>
          {rest.countDisplay && (
            <p className="count-heading">{cardData?.length} Courses</p>
          )}
        </div>
        {!loading && cardData?.length >= 10 ? (
          <Link
            to="viewallcontent"
            style={{
              marginRight: "2rem",
              fontSize: "1.7rem",
              fontWeight: "600",
            }}
            className="viewAllActive"
            onClick={handleViewAll}
          >
            {`${userDetails?.languagejson?.Home_Page?.View_all || "View all"}`}
          </Link>
        ) : (
          ""
        )}
      </div>
      <div className="swiper_wrapper">
        {!loading && cardData && cardData?.length > 0 && (
          <Swiper
            ref={sliderRef}
            breakpoints={
              rest.classDefine === "item-2" ? breakpoints3 : breakpoints
            }
            spaceBetween={8}
            slidesPerGroup={4}
            slidesPerView={4}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onInit={() => {}}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: false }}
            className="slide-container"
          >
            {cardData.map((item, idx) => (
              <SwiperSlide
                key={"ddlJ" + idx}
                className="slider-container"
                tabIndex={1}
              >
                {item.ref_type === ref_types["course"] && (
                  <CardLarge
                    title={item?.title || item?.tname[0]?.title || item?.tn}
                    tid={item.tid}
                    count={item.noofnuggets}
                    handleBookmark={addAndRemoveBookmark}
                    star={item.ravg || item.rating}
                    comp_date={item.comp_date}
                    sub_date={item.sub_date}
                    bookmark={item.bk}
                    heading={heading}
                    cobj={item.cobj}
                    tobj={item.tobj}
                    handleLocation={() => {
                      if (rest.loadingPage === true) {
                        history.push("/");
                      } else {
                        handleLocation(
                          item.tid,
                          item.otype,
                          item.title,
                          item.objid,
                          item.oname
                        );
                      }
                    }}
                    index={idx}
                    elseWhere={true}
                  />
                )}
                {item.ref_type === ref_types["content"] && (
                  <ContentCard
                    otype={item.otype}
                    title={item.title || item.oname || item.tname[0]?.title}
                    tid={item.tid}
                    dur={item.dur}
                    bookmark={item.bk}
                    star={item?.sortdt?.rate}
                    handleBookmark={addAndRemoveBookmarkContent}
                    handleLocation={() =>
                      handleLocation(
                        item.tid,
                        item.otype,
                        item.title,
                        item.objid,
                        item.oname
                      )
                    }
                    index={idx}
                    elseWhere={true}
                  />
                )}
                {item.ref_type === ref_types["session"] && (
                  <CardPrimary
                    auth={true}
                    title={item.sn}
                    img={
                      config.aws_org_id === "ICICI"
                        ? defaultIm
                        : item.sid &&
                          `${
                            config.aws_cloudfront_url
                          }${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                            item.sid
                          }.png`
                    }
                    addToBookMarkList={() => {}}
                    addToFavorite={item.EI}
                    sessionType={item.stype}
                    sessionDate={
                      item.date_list
                        ? item.date_list[0]?.date === ""
                          ? "yet to be scheduled"
                          : moment(
                              new Date(
                                item.date_list[0]?.combineStartTime
                              ).getTime()
                            ).format("DD MMM YYYY")
                        : item.startdate
                        ? moment(parseInt(item.startdate)).format("DD MMM YYYY")
                        : "yet to be scheduled"
                    }
                    handleLocation={() => {
                      history.push(`/session?sid=${item.sid}`);
                      dispatch(session(item));
                    }}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <span
          id="swiper-right"
          title="Right arrow"
          tabIndex={18}
          className={`arrow arrow-right ${
            cardData?.length === 0 ? "hide" : ""
          }`}
          onClick={handleNext}
        ></span>
        <span
          id="swiper-left"
          className={`arrow arrow-left ${cardData?.length === 0 ? "hide" : ""}`}
          onClick={handlePrev}
          title="Left arrow"
          tabIndex={18}
        ></span>
        <div className="flex-loading">
          {loading && (
            <React.Fragment>
              {[...Array(4).fill(0)].map((_, index) => (
                <Loading />
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
      {cardData?.length === 0 && (
        <div
          className="slick-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{
            opacity: 0,
            transition: { duration: 1, ease: "easeInOut" },
          }}
        ></div>
      )}
    </div>
  );
};
export default React.memo(Carousel);
